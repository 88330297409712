/**
 * 参考文档：http://fabricjs.com/fabric-intro-part-2#text
 *
 *
 */

// options 字段
let mockOptions = {
    left: 0,
    top: 0,

    fontFamily: null,
    fontSize: null,
    fontWeight: 'normal',       // normal、bold
    stroke: '#c3bfbf',
    strokeWidth: 3,

    underline: false,
    linethrough: false,
    overline: false,
    shadow: null,    // 'rgba(0,0,0,0.2) 0 0 5px'

    textAlign: null,
    lineHeight: null
}
let type = 'XText'
const XText = fabric.util.createClass(fabric.Textbox, {
    type,

    /**
     * 初始化
     * @param text
     * @param options
     */
    initialize(text, options = {}) {
        if (!options.id) {
            this.id = `${type}-${new Date().getTime()}`
        }
        this.callSuper('initialize', text, options);
    },
    /**
     * 转为JSON对象
     */
    toObject() {
        return fabric.util.object.extend(this.callSuper('toObject'), {
            id: this.get('id')
        });
    },
    /**
     * 渲染
     * @param ctx
     * @private
     */
    _render(ctx) {
        this.callSuper('_render', ctx);
        // ctx.font = '20px Helvetica';
        // ctx.fillStyle = '#333';
        /*ctx.font = this.labelFont;
        ctx.fillStyle = this.labelFill;*/
        // ctx.fillText(this.label, -this.width/2, -this.height/2 + 20);
        // ctx.fillText(this.label, 0, 0 + 10);
    },

});
fabric.XText = XText
fabric.XText.fromObject = (options, callback) => {
    // return fabric.Object._fromObject('LabelText', object, callback);
    callback && callback(new fabric.XText(options.text, options));
}

export default XText
