<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
export default {
  name: 'App'
}
</script>

<style>
@import '@/assets/iconfont/iconfont.css';
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

.form-unit {
    font-size: 15px;
    font-style: oblique;
    color: #6379bb;
    border-bottom: 1px solid #ddd;
    margin: 8px 0px 10px 0px;
    padding-bottom: 5px;
}
.block {
    height: 10px;
}

/* 滚动条样式设置 */

::-webkit-scrollbar {
    width: 8px;
    background: transparent;
    height: 8px;
}

::-webkit-scrollbar-thumb {
    background: #ddd;
    width: 8px;
    border-radius: 8px;
}
</style>
