<template>
    <div class="container">
        <div class="canvas" ref="canvas"></div>
<!--        <div class="properties-panel-wrapper">
        </div>-->

        <x-bpmn-property-panel class="properties-panel-wrapper" v-if="bpmnModeler" :modeler="bpmnModeler" ></x-bpmn-property-panel>


    </div>
</template>

<script>
import BpmnModeler from 'bpmn-js/lib/Modeler'

// bpmn 工作流绘图工具的样式
import "bpmn-js/dist/assets/diagram-js.css"  // 左边工具栏以及编辑节点的样式
import "bpmn-js/dist/assets/bpmn-font/css/bpmn.css"
import "bpmn-js/dist/assets/bpmn-font/css/bpmn-codes.css"
import "bpmn-js/dist/assets/bpmn-font/css/bpmn-embedded.css"

// 预览流程图
// import BpmnViewer from "bpmn-js/lib/Viewer"


// import {modules as paletteModules} from '../palette/index'
// import {modules as contextPadModules} from '../context-pad/index'
// import XBpmnModeler from '../modeler/modeler'

// 自定义的 properties-panel内容
// import propertiesProviderModule from '../properties-panel-extension/provider/authority';
// 引入描述文件
// import authorityModdleDescriptor from '../properties-panel-extension/descriptors/authority'

// 汉化
import customTranslate from "../translate";
import XBpmnPropertyPanel from "@/extends/x-bpmn/components/XBpmnPropertyPanel";

import minimapModule from 'diagram-js-minimap';
import 'diagram-js-minimap/assets/diagram-js-minimap.css'


import flowableDescriptor from '../flowable/descriptor.json'


/* https://github.com/GoldSubmarine/workflow-bpmn-modeler */
export default {
    name: "XBpmnDesigner",
    components: {XBpmnPropertyPanel},
    props: {
        height: String | Number,
        xml: String,

    },
    provide() {
        return {
            $designer: this,
            $bpmnModeler: this.bpmnModeler
        }
    },
    data() {
        return {
            bpmnModeler: null, // bpmn 建模器
            container: null,
            canvas: null,


            emptyXml: `<?xml version="1.0" encoding="UTF-8"?>
        <bpmn2:definitions xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:bpmn2="http://www.omg.org/spec/BPMN/20100524/MODEL" xmlns:bpmndi="http://www.omg.org/spec/BPMN/20100524/DI" xmlns:dc="http://www.omg.org/spec/DD/20100524/DC" xmlns:di="http://www.omg.org/spec/DD/20100524/DI" xsi:schemaLocation="http://www.omg.org/spec/BPMN/20100524/MODEL BPMN20.xsd" id="sample-diagram" targetNamespace="http://bpmn.io/schema/bpmn">
          <bpmn2:process id="Process_1">

          </bpmn2:process>
          <bpmndi:BPMNDiagram id="BPMNDiagram_1">
            <bpmndi:BPMNPlane id="BPMNPlane_1" bpmnElement="Process_1">

            </bpmndi:BPMNPlane>
          </bpmndi:BPMNDiagram>
        </bpmn2:definitions>`,

            /*<bpmndi:BPMNShape id="_BPMNShape_StartEvent_2" bpmnElement="StartEvent_1">
                <dc:Bounds height="36.0" width="36.0" x="412.0" y="240.0"/>
              </bpmndi:BPMNShape>*/

        }
    },
    mounted() {
        this.init()
    },
    methods: {
        init() {
            // 获取canvas的dom节点
            const canvas = this.$refs.canvas

            // 将汉化包装成一个模块
            const customTranslateModule = {
                translate: ["value", customTranslate]
            };

            let additionalModules = [
                minimapModule,
                /*BpmnPropertiesPanelModule,
                    BpmnPropertiesProviderModule,*/
                customTranslateModule
                // ...paletteModules,
                // ...contextPadModules

            ]

            // 建模
            this.bpmnModeler = new BpmnModeler({
                container: canvas,
                keyboard: {bindTo: document},
                height: this.height,
                propertiesPanel: {
                    // parent: '#js-properties-panel'
                },
                additionalModules,
                moddleExtensions: {
                    // camunda: camundaModdleDescriptor,
                    // authority: authorityModdleDescriptor
                    flowable: flowableDescriptor
                },

            })

            this.bpmnModeler.get('minimap').open()


            this.createNewDiagram(this.xml)

            // 事件查考，https://github.com/bpmn-io/diagram-js/blob/master/lib/features/modeling/Modeling.js
            this._addBpmnListener()
            this._addModelerListener()
            this._addEventBusListener()
        },

        createNewDiagram(xmlStr = this.emptyXml) {
            // 将字符串转换成图显示出来
            if (this.$strings.isBlank(xmlStr)) {
                xmlStr = this.emptyXml
            }
            return new Promise(async (resolve, reject) => {
                try {
                    const result = await this.bpmnModeler.importXML(xmlStr || '')
                    const {warnings} = result

                    // 让图能自适应屏幕
                    const canvas = this.bpmnModeler.get('canvas')
                    canvas.zoom('fit-viewport')
                    resolve()
                } catch (err) {
                    reject(err)
                }
            })
        },
        toggleMiniMap() {
            this.bpmnModeler && this.bpmnModeler.get('minimap').toggle()
        },
        // 放大缩小
        zoomViewport(zoomIn = true) {
            this.zoom = this.modeler.get('canvas').zoom()
            this.zoom += (zoomIn ? 0.1 : -0.1)
            this.modeler.get('canvas').zoom(this.zoom)
        },
        getProcessElement() {
            /*const rootElements = this.bpmnModeler.getDefinitions().rootElements
            for (let i = 0; i < rootElements.length; i++) {
                if (rootElements[i].$type === 'bpmn:Process') return rootElements[i]
            }*/
            const elementRegistry = this.bpmnModeler.get('elementRegistry')
            return elementRegistry.find(it => it.type === 'bpmn:Process')
        },
        getProcess() {
            const element = this.getProcessElement()
            console.log(element)
            let bo = element.businessObject
            return {
                id: element.id,
                name: bo.name,
                category: bo.$attrs['flowable:processCategory'],
                description: bo.documentation?.[0]?.text || ''
            }
        },
        _addBpmnListener() {
            const that = this
            // 给图绑定事件，当图有发生改变就会触发这个事件
            this.bpmnModeler.on('commandStack.changed', (e) => {
                /*this.bpmnModeler.saveXML({format: true}, (err, xml) => {
                    this.$emit('commandStack.changed', {xml})
                })*/
            })

        },
        _addModelerListener() {
            const elementRegistry = this.bpmnModeler.get('elementRegistry')

            const shapeEvents = ['shape.append', 'shape.added', 'shape.move.end', 'shape.removed']

            shapeEvents.forEach((eventName) => {
                this.bpmnModeler.on(eventName, e => {
                    let shape = e.element ? elementRegistry.get(e.element.id) : e.shape
                    this.$emit(eventName, shape)
                })
            })
        },
        _addEventBusListener() {
            const eventBus = this.bpmnModeler.get('eventBus') // 需要使用eventBus
            const eventTypes = ['element.click', 'element.changed'] // 需要监听的事件集合
            const elementRegistry = this.bpmnModeler.get('elementRegistry')
            let that = this
            /*eventTypes.forEach((eventName) => {
                eventBus.on(eventName, (e) => {
                    console.log(eventName, e.element, this.getProcessElement(), elementRegistry)
                    if (!e || e.element.type == 'bpmn:Process') {
                        that.selectedElement = e.element
                        return // 忽略根元素（画布）的click的事件
                    }

                    let shape = this.getShape(e.element.id) // 传递id进去

                    that.selectedElement = e.element
                    this.$emit(eventName, {shape, e: e.element})
                })
            })*/

            /*eventBus.on('selection.changed', (e) => {
                const selectionArray = e.newSelection
                this.selectedElement = selectionArray?.length ? selectionArray[0] : this.getProcessElement()

            })*/

        },

        getShape(elementId) {
            const elementRegistry = this.bpmnModeler.get('elementRegistry')
            return elementRegistry.get(elementId)
        },
        isSequenceFlow(type) {
            // 判断是否是线
            return type === 'bpmn:SequenceFlow'
        },
        toSVG(fileName) {
            fileName = fileName || (`${this.getProcessElement()?.id || 'export'}.svg`)
            return this.bpmnModeler.saveSVG().then(({svg}) => {
                this._download(fileName, svg, 'image/svg+xml;charset=utf-8')
            })
        },

        toXML(fileName ) {
            fileName = fileName || (`${this.getProcessElement()?.id || 'export'}.bpmn20.xml`)
            return this.bpmnModeler.saveXML({format: true}).then(({xml}) => {
                this._download(fileName, xml, 'text/plain')
            })
        },
        toXMLString() {
            return this.bpmnModeler.saveXML({format: true})
        },
        _download: (fileName, data, contentType) => {
            //这里res.data是返回的blob对象
            let blob = new Blob([data], {type: contentType});

            if (window.navigator.msSaveOrOpenBlob) {
                // 如果是IE浏览器
                navigator.msSaveBlob(blob, fileName);//filename文件名包括扩展名，下载路径为浏览器默认路径
                return
            }

            // chrome、Firefox
            let downloadElement = document.createElement('a');
            let href = window.URL.createObjectURL(blob); //创建下载的链接
            downloadElement.href = href;
            downloadElement.download = fileName
            document.body.appendChild(downloadElement);
            downloadElement.click(); //点击下载
            document.body.removeChild(downloadElement); //下载完成移除元素
            window.URL.revokeObjectURL(href); //释放掉blob对象
        }
    }
}
</script>

<style lang="scss" scoped>
.container {
    width: 100%;
    height: calc(100% - 41px);
    position: relative;
}

.properties-panel-wrapper {
    position: absolute;
    //left: 70%;
    top: 0;
    height: 100%;

    /*box-sizing: border-box;
    width: 400px;
    height: 100%;
    border-left: 1px solid #409EFF;*/
}

::v-deep .djs-minimap {
    top: unset;
    right: unset;
    left: 20px;
    bottom: 20px;

    .toggle {
        display: none;
    }
}
</style>
