
// dpi = 每一英寸长度中，取样、可显示或输出点的数目
// 1 英寸 = 25.4 毫米
// 以200dpi打印机为例，宽度60mm，换算成英寸为 60 / 25.4 = 2.36，纸张像素点 = 2.36 * 200 = 472

class Cpcl {
    _rawCommands = []
    _width = 472
    _height = 472

    /**
     * 配置项如下
     * <!> {offset} <200> <200> {height} {qty}
     * width: 标签纸的宽度，单位像素點，
     * height: 标签纸的高度，单位像素點
     * 8像素=1mm
     * printNum: 打印张数，默认为1
     * rotation：页面整体旋转 1-90度 2-180度 3-270度 其他-不旋转
     */
    creatNewPage(width = 472, height = 472, printNum = 1, rotation = 0, offset = 0) {
        this._rawCommand = []
        this._width = width
        this._height = height

        this._rawCommand.push(`! ${offset} 200 200 ${height} ${printNum}`)
        this._rawCommand.push(`ENCODING UTF-8`)
        this._rawCommand.push(`PAGE-WIDTH ${width}`)
        /* if (rotation == 1)
            strCmd += "ZPROTATE90\n";
        else if (rotation == 2)
            strCmd += "ZPROTATE180\n";
        else if (rotation == 3)
            strCmd += "ZPROTATE270\n";
        else
            strCmd += "ZPROTATE0\n"; */
    }

    /**
     * FORM 命令可以指示打印机在一页打印结束后切换至下一页顶部。
     */
    addForm() {
        this._rawCommand.push(`FORM`)
    }


    /**
     * 用于在标签上添加文本。这项命令及其各衍生命令可以控制使用的具体字体号和大小、标签上文本的位置以及文本的方向。标准常驻字体能够以 90 度的增量旋转
     * @param x: 文字方块左上角X座标，单位dot
     * @param y: 文字方块左上角Y座标，单位dot
     * @param content: 文字内容
     * @param fontName: 字体名称/编号。
     *          - 1 ==> 8×12（英文字体）
     - 55 ==> 16×16（GB18030）
     - 4 ==> 32×32（GB18030）
     - 其他默认 ==> 24×24（GB2312）
     * @param fontSize: 字体的大小标识。 有效字体大小范围是 0 至 7。
     * @param rotation: 旋转 1-90度 2-180度 3-270度 其他-不旋转
     */
    addText(x, y, content, fontName = 'GBUNSG24.CPF', fontSize = 0, rotation = 0) {
        let command = '';
        if (rotation == 1) {
            command += 'TEXT90';
        }
        if (rotation == 2) {
            command += 'TEXT180';
        }
        if (rotation == 3) {
            command += 'TEXT270';
        } else {
            command += 'TEXT';
        }

        // {command} {font} {size} {x} {y} {data}
        this._rawCommand.push(`${command} ${fontName} ${fontSize} ${x} ${y} ${content}`)
    };

    addTextAndWordWrap(x, y, content, fontName = 'GBUNSG24.CPF') {
        // 可使用文本宽度，  暂只考虑水平打印
        let canUseTextWidth = this._width - x

        // 每行最多显示字数
        let maxWordOfLine = Math.floor(canUseTextWidth / 24) - 1
        let wordCount = content.length
        let rows = wordCount % maxWordOfLine == 0 ? wordCount / maxWordOfLine : (Math.floor(wordCount / maxWordOfLine)) + 1

        for (let i = 0; i < rows; i++) {
            let textOfLine = content.substr(i * maxWordOfLine, maxWordOfLine)
            this.addText(x, i * 30 + y, textOfLine, fontName)
        }
    }

    /**
     * 用于给文本加下划线。仅当所使用的字体支持下划线时，此命令才会起作用。如果所使用的字体不支持 UNDERLINE，则将忽略此命令。
     * 以下字体支持 UNDERLINE： GBUNSN24.CPF GBUNSN24.CPF
     * @param mode  取值：on、off
     */
    addUnderline(mode = 'on') {
        this._rawCommand.push(`UNDERLINE ${mode}`)
    }

    /**
     * 使文本加粗并且稍微加宽
     * @param value 介于 0 到 5 之间的偏移量
     */
    addBold(mode = 'on') {
        this._rawCommand.push(`!U1 SETBOLD ${mode}`)
    }

    /**
     * 绘制任何长度、宽度和角度方向的线条
     * @param x0 左上角的 X 坐标
     * @param y0 左上角的 Y 坐标
     * @param x1 右上角的 X 坐标
     * @param y1 右上角的 Y 坐标
     * @param width 线条的单位宽度
     */
    addLine(x0, y0, x1, y1, width = 1) {
        // {command} {x 0 } {y 0 } {x 1 } {y 1 } {width}
        this._rawCommand.push(`LINE ${x0} ${y0} ${x1} ${y1} ${width} `)
    }

    /**
     * 绘制具有指定线条宽度的矩形
     * @param x0 左上角的 X 坐标
     * @param y0 左上角的 Y 坐标
     * @param x1 右下角的 X 坐标
     * @param y1 右下角的 Y 坐标
     * @param width 线条的单位宽度
     */
    addBox(x0, y0, x1, y1, width = 1) {
        this._rawCommand.push(`BOX ${x0} ${y0} ${x1} ${y1} ${width} `)
    }


    /**
     * 以指定的宽度和高度纵向和横向打印条码
     * @param type 从下表中选择，默认为 code128
     *         // 符号                               用法
     *         // UPC-A                             UPCA、UPCA2、UPCA5
     *         // UPC-E                             UPCE、UPCE2、UPCE5
     *         // EAN/JAN-13                        EAN13、EAN132、EAN135
     *         // EAN/JAN-8                         EAN8、EAN82、EAN 85
     *         // Code 39                           39、39C、F39、F39C
     *         // Code 93/Ext.93                    93
     *         // Interleaved 2 of 5                I2OF5
     *         // Interleaved 2 of 5（带 checksum）  I2OF5C
     *         // German Post Code                  I2OF5G
     *         // Code 128（自动）                   128
     *         // UCC EAN 128                       UCCEAN128
     *         // Codabar                           CODABAR、CODABAR16
     *         // MSI/Plessy                        MSI、MSI10、MSI1010、MSI1110
     *         // Postnet                           POSTNET
     *         // FIM                               FIM
     * @param x     左上角的 X 坐标
     * @param content 条码内容
     * @param y     左上角的 Y 坐标
     * @param width 宽度（条形码竖线的宽度）
     * @param height    高度
     * @param showText 条形码下发是否显示文本
     * @param ratio    高宽比，默认为1
     */
    addBarCode( x, y,content = '', width = 1, height, showText = true, type = '128', ratio = 1,) {

        // {command} {type} {width} {ratio} {height} {x} {y} {data}
        // command: BARCODE（或 B）：横向打印条码。 VBARCODE（或 VB） 纵向打印条码
        // type：

        if (showText) {
            // 条码文本  {command} {font number} {font size} {offset}
            // {font number}：注释条码时要使用的字体号。
            // {font size}：注释条码时要使用的字体大小。
            // {offset}：文本距离条码的单位偏移量。
            this._rawCommand.push(`BARCODE-TEXT 7 0 5`)
        }
        this._rawCommand.push(`BARCODE ${type} ${width} ${ratio} ${height} ${x} ${y} ${content}`)

        if (!showText) {
            this._rawCommand.push(`BARCODE-TEXT OFF`)
        }
    }


    /**
     *
     * @param content
     * @param x
     * @param y
     * @param correctionLevel 纠错级别应为以下符号之一：
     *                              H - 极高可靠性级别（H 级）；
     *                              Q - 高可靠性级别（Q 级）；
     *                              M - 标准级别（M 级）；
     *                              L - 高密度级别（L 级）
     * @param dataInputMode 字符模式符号：N – 数字；A - 字母数字；
     */
    addQrCode(x, y,content, correctionLevel = 'M', dataInputMode = 'A') {
        // {command} {type} {x} {y} [M n] [U n]
        // {data}
        // [M n]：QR Code 规范编号。选项是 1 或 2。QR Code Model 1 是原始规范，而 QR Code Model 2 则是该符号的经过增强后的形式。Model 2 提供了附加功能，而且可以自动与 Model 1 进行区分。Model 2 为推荐规范，是默认值。
        // [U n]：模块的单位宽度/单位高度。范围是 1 至 32。默认值为 6。
        // {data} 除了包含实际的输入数据字符串外，还包含一些模式选择符号。输入数据类型可以由打印机软件自动识别，也可以通过手动方式设置。模式选择符号和实际数据之间有一个分隔符（逗号）
        // <Error Correction Level><Mask No.><Data Input Mode (should be “A”)>,<DataCharacter String>

        this._rawCommand.push(`BARCODE QR  ${x} ${y} M 2 U 6`)
        this._rawCommand.push(`${correctionLevel}${dataInputMode},${content} `)
        this._rawCommand.push(`ENDQR `)
    }

    /**
     * DataMatrixCode
     * @param content
     * @param x
     * @param y
     * @param height
     * @param qualityLevel  Accepted Values for n: 0, 50, 80, 100, 140, 200
     */
    addDataMatrixCode(content, x, y, scale = 1, qualityLevel = 0, rows = 6) {
        // {command} {type} {x} {y} [H n] [S n] [C n] [R n] [F n]
        // {data}
        // qualityLevel：
        // [C n] = columns to encode
        // [R n] = rows to encode
        // Accepted Values for n:
        // 1 = field data is numeric + space (0..9,‖) – No \&’’
        // 2 = field data is uppercase alphanumeric + space (A...Z,’’) – No \&’’
        // 3 = field data is uppercase alphanumeric + space, period, comma, dash, and
        // slash (0...9, A...Z, ―.-/‖)
        // 4 = field data is upper-case alphanumeric + space (0...9, A...Z,’’) – no \&’’
        // 5 = field data is full 128 ASCII 7-bit set
        // 6 = field data is full 256 ISO 8-bit set
        // Default Value: 6
        this._rawCommand.push(`BARCODE DATAMATRIX  ${x} ${y} H ${scale} S ${qualityLevel} C ${rows} R ${rows} F 6`)
        this._rawCommand.push(`${content} `)
        this._rawCommand.push(`ENDDATAMATRIX `)
    }

    addPrint() {
        this._rawCommand.push(`PRINT`)

        // 确保使用回车和换行字符结束此项及所有命令
        this._rawCommand.push(`\r\n`)
    };


    // ===================================================以下为打印机设置===================================================
    /**
     * 将常驻字体放大指定的放大倍数
     * @param w 字体的宽度放大倍数。有效放大倍数为 1 到 10。
     * @param h 字体的高度放大倍数。有效放大倍数为 1 到 10。
     */
    rotationFontSize(w = 0, h = 0) {
        this._rawCommand.push(`SETMAG ${w} ${h} `)
    }

    /**
     * 指定整个标签的打印黑度。最亮的打印输出为对比度级别 0。最暗的对比度级别为 3。
     * @param level  0-3
     */
    setDarkness(level) {
        this._rawCommand.push(`CONTRAST ${level} `)
    }

    /**
     * 用于设置电机的最高速度级别
     * @param level 一个介于 0 到 5 之间的数字，0 表示最低速度。
     */
    setSpeed(level) {
        this._rawCommand.push(`SPEED ${level} `)
    }

    // ===================================================以上为打印机设置===================================================

    getDemoLabelCommand() {
        let command = `! 0 200 200 472 1
            ENCODING UTF-8
            BOX 10 10 452 452 1
            TEXT GBUNSG24.CPF 0 20 20 演示项目
            TEXT GBUNSG24.CPF 0 346 20 物料标签
            LINE 20 48 452 48 1

            TEXT GBUNSG24.CPF 0 20 60 物料名称
            TEXT GBUNSG24.CPF 0 156 60 第一行物料名称
            TEXT GBUNSG24.CPF 0 156 80 第二行物料名称

            LINE 20 160 452 160 1

            TEXT GBUNSG24.CPF 0 20 170 物料编码
            TEXT GBUNSG24.CPF 0 156 170 物料编码
            LINE 20 200 452 200 1

            TEXT GBUNSG24.CPF 0 20 210 批次号
            BARCODE 128 1 1 60 156 210 L22041400001

            LINE 126 48 126 452 1
            FORM
            PRINT
        `
        return command
    }

    clear() {
        this._rawCommand = []
    }

    toCommandText() {
        return this._rawCommand.join('\r\n')
    }


    _stringToArrayBuffer(str) {
        let out = new ArrayBuffer(str.length);
        let uint8 = new Uint8Array(out);
        let strs = str.split('');
        for (let i = 0; i < strs.length; i++) {
            uint8[i] = strs[i].charCodeAt();
        }
        return uint8;
    }


    /**
     * 蓝牙打印需要发送二进制数据
     * @returns {Uint8Array}
     */
    toBuffer() {
        let command = this.toCommandText()
        return this._stringToArrayBuffer(command)
    }
}


export default new Cpcl()


/**
 * 图片打印指令
 * x: 文字方块左上角X座标，单位dot
 * y: 文字方块左上角Y座标，单位dot
 * data{
            threshold,//0/1提取的灰度级
            width,//图像宽度
            height,//图像高度
            imageData , //图像数据
    }
 */
export function addImageCmd(x, y, data) {
    let strImgCmd = '';
    const threshold = data.threshold || 180;
    let myBitmapWidth = data.width,
        myBitmapHeight = data.height;
    let len = parseInt((myBitmapWidth + 7) / 8); //一行的数据长度
    //console.log('len=',len);
    //console.log('myBitmapWidth=',myBitmapWidth);
    //console.log('myBitmapHeight=',myBitmapHeight);
    let ndata = 0;
    let i = 0;
    let j = 0;
    let sendImageData = new ArrayBuffer(len * myBitmapHeight);
    sendImageData = new Uint8Array(sendImageData);
    let pix = data.imageData;

    for (i = 0; i < myBitmapHeight; i++) {
        for (j = 0; j < len; j++) {
            sendImageData[ndata + j] = 0;
        }
        for (j = 0; j < myBitmapWidth; j++) {
            const grayPixle1 = grayPixle(pix.slice((i * myBitmapWidth + j) * 4, (i * myBitmapWidth + j) * 4 + 3));
            if (grayPixle1 < threshold)
                sendImageData[ndata + parseInt(j / 8)] |= (0x80 >> (j % 8));

        }
        ndata += len;
    }
    //console.log('sendImageData=',sendImageData);
    //指令图片数据
    strImgCmd += 'EG ' + len + ' ' + myBitmapHeight + ' ' + x + ' ' + y + ' ';
    for (i = 0; i < sendImageData.length; i++) {
        strImgCmd += Hex2Str(sendImageData[i]);
    }
    strImgCmd += '\n';
    //console.log(strImgCmd);
    return strImgCmd;
}
