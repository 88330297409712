<template>
    <div>
        <el-card class="box-card">
            <el-form label-width="70px" @submit.native.prevent size="small">
                <el-form-item label="内容">
                    <el-input
                        type="textarea"
                        :rows="14"
                        placeholder="请输入内容"
                        v-model="formModel.content" resize="none" show-word-limit>
                    </el-input>
                </el-form-item>

                <el-row>
                    <el-form-item>
                        <el-button @click="handleDockerToCompose" >Docker 转 Compose</el-button>
                        <el-button @click="handleComposeToDocker" >Compose 转 Docker</el-button>

                        <el-button @click="handleClear" type="danger">清空</el-button>


                        <el-divider direction="vertical"></el-divider>
                        <el-link type="primary" href="https://docs.docker.com/reference/" target="_blank">Reference documentation</el-link>
                    </el-form-item>
                </el-row>

            </el-form>
        </el-card>
        <div style="height: 10px"></div>
        <el-card class="box-card code-container" :body-style="{ paddingBottom: '0px' }">
            <el-form label-width="70px" @submit.native.prevent size="small">

                <el-form-item label="结果">
                    <el-input
                        type="textarea"
                        :rows="14"
                        placeholder="结果"
                        v-model="formModel.result" resize="none" show-word-limit>
                    </el-input>
                </el-form-item>
            </el-form>

        </el-card>


    </div>
</template>

<script>
const convertDockerRunToCompose = require('composerize');
const convertToDockerRunCommands = require('decomposerize');


export default {
    name: "DockerUtils",
    components: {},
    data() {
        return {
            formModel: {

                content: 'docker run -p 80:80 -v /var/run/docker.sock:/tmp/docker.sock:ro --restart always --log-opt max-size=1g nginx',
            },

        }
    },
    computed: {},
    methods: {
        handleDockerToCompose() {
            let content = this.formModel.content
            let result = convertDockerRunToCompose(content, null, 'latest', 2)
            this.$set(this.formModel, 'result', result)
        },
        handleComposeToDocker() {
            let content = this.formModel.content
            let result = convertToDockerRunCommands(content)
            this.$set(this.formModel, 'result', result)
        },
        handleClear() {
            this.$set(this.formModel, 'content', '')
            this.$set(this.formModel, 'result', '')
        }
    },
    created() {

    }
}
</script>

<style scoped>

.code-container {
    width: 100%;
    /*height: 40vh;*/
    overflow-y: auto;
}

.code-wrapper {
    display: inline-block;
    margin-right: 10px
}

.el-radio {
    margin-right: 10px;
}
</style>
