/**
 * 刻度尺
 */

// 定义一个刻度尺类
class XRuler extends fabric.Group {
    constructor(options = {}) {
        super([], options);
        this.width = this.canvas.width; // 刻度尺宽度
        this.height = 30; // 刻度尺高度
        this.spacing = 10; // 刻度间距
        this.showRuler = true; // 是否显示刻度尺
        this.showUnit = true; // 是否显示单位
        this.unit = 'mm'; // 刻度尺单位
        this.createRuler();


        // 将刻度尺相关对象设置为 excludeFromExport
        this.excludeFromExport = true;
    }

    // 生成刻度尺
    createRuler() {
        // 生成背景矩形
        const bgRect = new fabric.Rect({
            width: this.width,
            height: this.height,
            fill: '#fff',
            stroke: '#000',
            strokeWidth: 1,
        });
        this.add(bgRect);

        // 生成刻度线和文字
        for (let i = 0; i <= this.width; i += this.spacing) {
            // 刻度线
            const line = new fabric.Line(
                [i, 0, i, this.height],
                { stroke: '#000', strokeWidth: 1 }
            );
            this.add(line);

            // 刻度文字
            const text = new fabric.Text(
                `${i / 10}`,
                { left: i + 2, top: this.height + 2, fontSize: 10 }
            );
            this.add(text);
        }

        // 单位
        const unitText = new fabric.Text(
            this.unit,
            { left: this.width - 20, top: this.height + 2, fontSize: 10 }
        );
        this.add(unitText);
    }

    // 更新刻度尺
    updateRuler() {
        // 清空原有刻度尺
        this._objects.splice(0, this._objects.length);

        if (this.showRuler) {
            // 生成刻度尺
            this.createRuler();
        }
    }

    // 设置刻度尺宽度和刻度间距
    setSpacing(spacing) {
        this.spacing = spacing;
        this.updateRuler();
    }

    // 设置是否显示刻度尺
    setShowRuler(show) {
        this.showRuler = show;
        this.updateRuler();
    }

    // 设置是否显示单位
    setShowUnit(show) {
        this.showUnit = show;
        this.updateRuler();
    }

    // 设置刻度尺单位
    setUnit(unit) {
        this.unit = unit;
        this.updateRuler();
    }
}


export default XRuler
