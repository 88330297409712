import axios from 'axios'
import {Message, Loading} from 'element-ui'


const instance = axios.create({
    baseURL: '/api',
});


const download = (resp) => {
    //这里res.data是返回的blob对象
    let blob = new Blob([resp.data], {type: resp.headers['content-type']});

    // 自定义响应头
    let fileName = resp.headers['content-filename'] && decodeURIComponent(resp.headers['content-filename'])
    if (fileName === undefined || fileName === null || fileName === "") {
        fileName = new Date().getTime() + '.xlsx'
    }

    if (window.navigator.msSaveOrOpenBlob) {
        // 如果是IE浏览器
        navigator.msSaveBlob(blob, fileName);//filename文件名包括扩展名，下载路径为浏览器默认路径
        return
    }

    // chrome、Firefox
    let downloadElement = document.createElement('a');
    let href = window.URL.createObjectURL(blob); //创建下载的链接
    downloadElement.href = href;
    downloadElement.download = fileName
    document.body.appendChild(downloadElement);
    downloadElement.click(); //点击下载
    document.body.removeChild(downloadElement); //下载完成移除元素
    window.URL.revokeObjectURL(href); //释放掉blob对象
}


// 添加请求拦截器
instance.interceptors.request.use(function (config) {
    if(config.url.indexOf('http://') != -1 || config.url.indexOf('https://') != -1) {
        return config
    }


    return config;
}, function (error) {
    // 对请求错误做些什么

    return Promise.reject(error);
});

// 添加响应拦截器
instance.interceptors.response.use(function (response) {
    let {autoDownload = false} = response.config

    // 处理文件下载
    let contentType = response.headers['content-type']
    if (autoDownload && response.headers && contentType
        && (contentType.indexOf('application/x-msdownload') != -1
            || contentType.indexOf('application/octet-stream') != -1
            || contentType.indexOf('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') != -1)) {
        download(response)
        return response
    }
    if(contentType.indexOf( 'application/json') == -1) {
        // 非JSON，直接返回
        return response;
    }

    /*let res = response.data
    if (!res.success && res.code != 0 ) {
        Message.error(res.message || res.msg);
        return Promise.reject(response)
    }*/

    return response.data
}, function (err) {
    // 如果返回的状态码为 200，错误信息交由程序处理。其他信息统一处理，避免重复处理
    if (err && err.response && (err.response.status == 200 || err.response.status == 302)) {
        return Promise.reject(err)
    }

    console.log('status code is', err);

    let needLogin = false
    if (err && err.response) {
        // 是否需要重新登录

        if (err.response.data.data && err.response.data.data.needLogin) {
            needLogin = true
        }
        switch (err.response.status) {
            case 400:
                err.message = '请求错误(400)';
                break;
            case 401:
                err.message = '权限不足(401)';
                // 需要重新登录
                if (needLogin) {
                    err.message = "会话已超时或已被踢出，请重新登录(401)"
                }
                break;
            case 403:
                err.message = `${err.response.data.message}(403)`;
                break;
            case 404:
                err.message = '请求出错(404)';
                break;
            case 408:
                err.message = '请求超时(408)';
                break;
            case 500:
                err.message = '服务器错误(500)';
                break;
            case 501:
                err.message = '服务未实现(501)';
                break;
            case 502:
                err.message = '网络错误(502)';
                break;
            case 503:
                err.message = '服务不可用(503)';
                break;
            case 504:
                err.message = '网络超时(504)';
                break;
            case 505:
                err.message = 'HTTP版本不受支持(505)';
                break;
            default:
                err.message = `连接出错(${err.response.status})!`;
        }
    } else {
        err.message = '连接服务器失败!'
    }
    Message.error(err.message);
    if (needLogin) {

    }


    return Promise.reject(err)
});

instance.download = (url) => {
    let loadingInstance = null
    return new Promise((resolve, reject) => {
        loadingInstance = Loading.service({
            fullscreen: true,
            body: true,
            text: `导出进度 0% ...`,
            spinner: 'el-icon-loading'
        })
        instance.get(url, {
            responseType: "blob",
            onDownloadProgress(progressEvent) {
                let {loaded, total} = progressEvent
                if (total == 0 || loaded == total) {
                    return
                }
                let progress = (loaded / total).toFixed(2) * 100
                loadingInstance = Loading.service({
                    fullscreen: true,
                    body: true,
                    text: `导出进度 ${progress}% ...`,
                    spinner: 'el-icon-loading'
                })
            }
        }).then(resp => {
            resolve(resp)
        }).catch(err => {
            reject(err)
        }).finally(() => {
            loadingInstance && loadingInstance.close()
        })
    })
}

export default instance;
