<template>
    <el-form label-width="80px" label-position="right">
        <el-form-item label="表单标识" v-if="cpShowConfig.formKey">
            <el-input v-model="formModel.formKey" placeholder="/views/xxx/xxx.vue" />
        </el-form-item>
        <el-form-item label="发起人" v-if="cpShowConfig.initiator">
            <el-input v-model="formModel.initiator"/>
        </el-form-item>

        <el-form-item label="人员类型" v-if="cpShowConfig.userType">
            <el-select v-model="formModel.userType" clearable style="width:100%">
                <el-option label="指定人员" value="assignee"></el-option>
                <el-option label="候选人员" value="candidateUsers"></el-option>
                <el-option label="候选组" value="candidateGroups"></el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="指定人员" v-if="cpShowConfig.assignee">
            <el-input v-model="formModel.assignee" allowCreate/>
        </el-form-item>
        <el-form-item label="候选人员" v-if="cpShowConfig.candidateUsers">
            <el-input v-model="formModel.candidateUsers" allowCreate/>
        </el-form-item>
        <el-form-item label="候选组" v-if="cpShowConfig.candidateGroups">
            <el-input v-model="formModel.candidateGroups" allowCreate/>
        </el-form-item>
    </el-form>
</template>

<script>


import mixinPanel from "@/extends/x-bpmn/common/mixinPanel";
import {commonParse} from '@/extends/x-bpmn/common/parseElement'

export default {
    name: "form-setting",
    mixins: [mixinPanel],
    setup() {
        return {}
    },
    data() {
        return {
            formModel: {}
        }
    },

    watch: {
        element() {
            this.initFormModel()
        },
        'formModel.formKey': function (val) {
            this.updateProperties({
                'flowable:formKey': val
            })
        },
        'formModel.initiator': function (val) {
            this.updateProperties({
                'flowable:initiator': val
            })
        },
        'formData.userType': function(val, oldVal) {
            if (oldVal) {
                const types = ['assignee', 'candidateUsers', 'candidateGroups']
                types.forEach(type => {
                    delete this.element.businessObject.$attrs[`flowable:${type}`]
                    delete this.formData[type]
                })
            }
        },
        'formData.assignee': function(val) {
            if (this.formData.userType !== 'assignee') {
                delete this.element.businessObject.$attrs[`flowable:assignee`]
                return
            }
            this.updateProperties({ 'flowable:assignee': val })
        },
        'formData.candidateUsers': function(val) {
            if (this.formData.userType !== 'candidateUsers') {
                delete this.element.businessObject.$attrs[`flowable:candidateUsers`]
                return
            }
            this.updateProperties({ 'flowable:candidateUsers': val?.join(',') })
        },
        'formData.candidateGroups': function(val) {
            if (this.formData.userType !== 'candidateGroups') {
                delete this.element.businessObject.$attrs[`flowable:candidateGroups`]
                return
            }
            this.updateProperties({ 'flowable:candidateGroups': val?.join(',') })
        },

    },
    methods: {
        initFormModel() {
            if (this.element == null) {
                return
            }
            this.formModel = commonParse(this.element)
        },
    },
    created() {
        this.initFormModel()

    }
}
</script>

<style scoped>

</style>
