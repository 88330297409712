export default {
    props: {
        element: null,
        canvas: null,
    },
    computed: {
        cpElementType() {
            return this.element?.type
        }
    },
    methods: {
        _beforeUpdate({key, value}) {
            if (key == 'width' || key == 'height') {
                let newWidth = key == 'width' ? value : this.element.width;
                let newHeight = key == 'height' ? value : this.element.height

                // 修改width、height后，显示不会变宽/高，只是left/top移动了
                let {left, top, width, height} = this.element

                // 调整 left 和 top，以保持对象的相对位置不变
                this.element.set({
                    left: left + (this.element.width - newWidth) / 2,
                    top: top + (this.element.height - newHeight) / 2,
                    width: newWidth,
                    height: newHeight,

                });
            }
        },
        _afterUpdate({key, value}) {
            let barQrCode = ['XQrCode', 'XBarCode']
            if (barQrCode.includes(this.cpElementType) && key == 'text') {
                this.element.reload()
                return
            }
        },
        updateProperties(properties) {
            Object.keys(properties).forEach(key => {

                this._beforeUpdate({key, value: properties[key]})

                this.element[key] = properties[key]

                this._afterUpdate({key, value: properties[key]})
            })
            this.canvas.requestRenderAll();
        },
    }
}
