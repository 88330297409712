<template>
    <div>
        <el-card class="box-card">
            <el-form label-width="70px" @submit.native.prevent size="small" inline>

                <!--  http://api.ipify.org/?format=json    http://ip-api.com/json/?lang=zh-CN              -->
                <el-form-item label="协议">
                    <el-radio-group v-model="formModel.protocol">
                        <el-radio label="http" border>http</el-radio>
                        <el-radio label="https" border>https</el-radio>
                        <el-radio label="socks5" border>socks5</el-radio>
                    </el-radio-group>
                </el-form-item>

                <el-form-item>
                    <el-button @click="handleTest">测试</el-button>
                </el-form-item>

            </el-form>
        </el-card>
        <div style="height: 10px"></div>

    </div>


</template>

<script>

const axios = require('axios');
const HttpProxyAgent = require('http-proxy-agent');
const HttpsProxyAgent = require('https-proxy-agent');


import {SocksProxyAgent} from 'socks-proxy-agent';

const http = require('http');
const https = require('https');

export default {
    name: "ProxyDetect",
    components: {},
    data() {
        return {
            formModel: {
                protocol: 'http'
            },
            codeValues: []
        }
    },
    computed: {},
    methods: {
        handleTest() {
            /* 代理检测在浏览器中不会生效 */

            // 代理服务器地址和端口号
            const proxyHost = 'yuntao.xyz';
            const proxyPort = 7890;

            // 可选：如果需要用户名和密码进行身份验证，可以指定这些凭据
            const proxyUsername = 'clash';
            const proxyPassword = '19970922aA';

            // 可选：如果您想测试 SOCKS5 代理，请使用 socks-proxy-agent 创建代理代理对象
            let proxyUrl = ``;
            let protocol = location.protocol == 'http:' ? 'http' : 'https'

            if (proxyUsername && proxyPassword) {
                proxyUrl = (`${protocol}://${proxyUsername}:${proxyPassword}@${proxyHost}:${proxyPort}`)
            } else {
                proxyUrl = (`${protocol}://${proxyHost}:${proxyPort}`)
            }

            let socks5ProxyAgent  = new SocksProxyAgent({
                hostname: proxyHost,
                port: proxyPort,
                userId: proxyUsername,
                password: proxyPassword
            })


            let proxy = {
                protocol,
                host: proxyHost,
                port: proxyPort,
                auth: (proxyUsername && proxyPassword) ? {
                    username: proxyUsername,
                    password: proxyPassword
                } : undefined
            }

            // 可选：如果您想测试 HTTP 或 HTTPS 代理，请使用 httpAgent 或 httpsAgent 创建代理代理对象
            let httpAgent = new HttpProxyAgent(proxyUrl);
            const httpsAgent = new HttpsProxyAgent(proxyUrl);

            // httpbin.org/ip、ifconfig.me/ip、ipinfo.io/ip
            const targetUrl = `http://httpbin.org/ip`;

            // 使用 axios 发送 GET 请求并记录响应时间和代理 IP
            const startTime = new Date();
            /*axios.get(targetUrl, {httpAgent:socks5ProxyAgent,httpsAgent:socks5ProxyAgent, proxy}) .then( response => {
                const endTime = new Date();
                const delay = endTime - startTime;
                console.log(`The response time is ${delay} ms.`);

                console.log( response.data)
            })
                .catch(error => console.error(error));*/
            /*
            fetch(targetUrl, { httpAgent, agent:httpAgent })
                .then(async response => {
                    const endTime = new Date();
                    const delay = endTime - startTime;
                    console.log(`The response time is ${delay} ms.`);

                    console.log(await response.json())
                })
                .catch(error => console.error(error));*/


             httpAgent = new http.Agent({
                proxy: {
                    host: proxyHost,
                    port: proxyPort,
                    auth: `${proxyUsername}:${proxyPassword}`
                }
            });
            http.get({
                hostname: 'httpbin.org',
                path: '/ip',
                agent: socks5ProxyAgent
            }, (res) => {
                console.log(res.statusCode);
                res.on('data', (d) => {
                    const endTime = new Date();
                    const delay = endTime - startTime;
                    console.log(`The response time is ${delay} ms.`);

                    console.log( res.data)
                });
            });
        }
    }
}
</script>

<style scoped>

</style>
