<template>
    <div class="full-height">

        <el-card class="box-card">
            <el-form label-width="70px" @submit.native.prevent size="small">
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="原字符串">
                            <el-input
                                type="textarea"
                                :rows="7"
                                placeholder="请输入内容"
                                v-model="formModel.content" resize="none">
                            </el-input>
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-row>
                    <el-col :span="24">
                        <el-form-item>
                            <el-button type="primary" @click="handleFormat">格式化</el-button>
                            <el-button @click="handleCompress">压缩</el-button>
                            <el-button @click="handleCopy">复制</el-button>
                            <el-button @click="handleClear">清空</el-button>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </el-card>
        <div style="height: 10px"></div>
        <el-card class="box-card ">
            <!--            <editor v-model="content" ref="aceEditor" @init="editorInit"
                                :lang="formModel.lang" theme="chrome" height="550" width="100%"
                                :options="options"
                        />-->
            <el-form label-width="70px" @submit.native.prevent size="small">
                <el-row :gutter="20">
                    <el-col :span="12">
                        <el-form-item label="结果">
                            <el-input
                                type="textarea"
                                :rows="20"
                                placeholder="结果"
                                v-model="formModel.result" resize="none">
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="">
<!--
                            <vue-json-pretty :data="formModel.resultJson"
                                             showIcon showLength
                                             style="height: 400px;overflow: auto"/>
-->


                            <vue-json-editor v-model="formModel.resultJson" :show-btns="false" :expandedOnStart="true"
                                             lang="zh"  style="height: 400px;overflow: auto"></vue-json-editor>

                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>

        </el-card>
    </div>

</template>

<script>
import VueJsonPretty from 'vue-json-pretty';
import 'vue-json-pretty/lib/styles.css';

import vueJsonEditor from 'vue-json-editor'

export default {
    name: "JsonFormatter",
    components: {
        // editor: require('vue2-ace-editor'),
        VueJsonPretty,

        vueJsonEditor
    },
    data() {
        return {
            formModel: {
                content: '',
                result: '',
                resultJson: null
            },
            options: {
                enableBasicAutocompletion: true,
                enableSnippets: true,
                enableLiveAutocompletion: true/*自动补全*/
            }
        }
    },
    methods: {
        editorInit() {
            // npm i xml-formatter
            // npm i js-beautify
            // require('brace/ext/language_tools') //language extension prerequsite...
            // require('brace/mode/html')
            // require('brace/mode/xml')
            // require('brace/mode/json')
            // require('brace/mode/javascript')    //language
            // require('brace/mode/less')
            // require('brace/theme/chrome')
            // require('brace/snippets/javascript') //snippet
            //
            // require('brace/ext/themelist')
        },
        handleFormat() {
            let {content} = this.formModel

            try {
                let value = JSON.parse(content)

                this.$set(this.formModel, 'result', JSON.stringify(value, null, 4));
                this.$set(this.formModel, 'resultJson', value);
            } catch (e) {

            }

            /*let editor = this.$refs.aceEditor.editor

                           console.log(beautify)
                           beautify(editor.session)
            let session = editor.getSession()

            if (lang == 'json') {
                try {
                    let value = JSON.parse(session.getValue())
                    session.setValue(JSON.stringify(value, null, 4));
                } catch (e) {

                }
            } else {
                let Mode = require(`brace/mode/${lang}`).Mode;
                session.setMode(new Mode());
            }*/
        },
        handleCompress() {
            let {content} = this.formModel
            try {
                let value = JSON.parse(content)

                this.$set(this.formModel, 'result', JSON.stringify(value));
                this.$set(this.formModel, 'resultJson', value);
            } catch (e) {

            }

            /*let {lang} = this.formModel

            let editor = this.$refs.aceEditor.editor
            let session = editor.getSession()
            if (lang == 'json') {
                try {
                    let value = JSON.parse(session.getValue())
                    session.setValue(JSON.stringify(value));
                } catch (e) {

                }
            } else {

            }*/
        },
        handleCopy(e) {
            // let editor = this.$refs.aceEditor.editor
            // let session = editor.getSession()
            //
            // this.$clipboard(session.getValue(), e)

            this.$clipboard(this.formModel.result, e)
        },
        handleClear() {
            this.content = ''
        }
    },
}
</script>

<style scoped>
.el-radio {
    margin-right: 10px;
}

::v-deep .jsoneditor-vue{
    height: 100%;
}
</style>
