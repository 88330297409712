export function debounce(func, wait, immediate) {
    let timer;
    return function () {
        let context = this,
            args = arguments;

        if (timer) clearTimeout(timer);
        if (immediate) {
            let callNow = !timer;
            timer = setTimeout(() => {
                timer = null;
            }, wait);
            if (callNow) func.apply(context, args);
        } else {
            timer = setTimeout(() => {
                func.apply(context, args);
            }, wait)
        }
    }
}

export function download(fileName, data, contentType = 'text/plain') {
    //这里res.data是返回的blob对象
    let blob = new Blob([data], {type: contentType});

    if (window.navigator.msSaveOrOpenBlob) {
        // 如果是IE浏览器
        navigator.msSaveBlob(blob, fileName);//filename文件名包括扩展名，下载路径为浏览器默认路径
        return
    }

    // chrome、Firefox
    let downloadElement = document.createElement('a');
    let href = window.URL.createObjectURL(blob); //创建下载的链接
    downloadElement.href = href;
    downloadElement.download = fileName
    document.body.appendChild(downloadElement);
    downloadElement.click(); //点击下载
    document.body.removeChild(downloadElement); //下载完成移除元素
    window.URL.revokeObjectURL(href); //释放掉blob对象
}
