<template>
    <div style="height: 100%;">
        <el-form :model="formModel" size="mini" label-width="70px"
                 ref="editForm" :show-message="false" :inline-message="false">
            <el-card class="box-card" style="height: 100%;">

                <el-row :gutter="20">
                    <el-col :span="10">
                        <div class="form-unit">服务器配置</div>

                        <el-form-item label="URL" prop="url">
                            <template slot="label">
                                <el-tooltip placement="top">
                                    <template slot="content">
                                        <div style="width: 350px">
                                            支持：ws、wss、sockjs协议。
                                            由于websocket不支持带cookie访问，如果有授权需求的话，可以在URL带参数。例如：http://localhost:8080/api/sockjs?Authorization=a535fae689a1aaf7c73e93ac097be7bb
                                        </div>
                                    </template>
                                    <i class="el-icon-question"></i>
                                </el-tooltip>
                                URL
                            </template>
                            <el-input v-model="formModel.url" auto-complete="off"
                                      placeholder="请输入服务器地址。例如：http://localhost:8080/api/websocket">
                                <template slot="append">
                                    <div>
                                        <el-button @click="initWebSocket" type="primary"
                                                   style="color: #FFF; background-color: #409EFF;border-color: #409EFF;"

                                                   v-if="!connected">连接
                                        </el-button>
                                        <el-button @click="disconnectWebSocket" type="warning" v-if="connected">断开
                                        </el-button>
                                    </div>
                                </template>
                            </el-input>
                        </el-form-item>

                        <el-form-item label="重试间隔" prop="retryInterval">
                            <el-input v-model="formModel.retryInterval" auto-complete="off"
                                      placeholder="请输入重试间隔（秒）。例如：10">
                                <template slot="append">
                                    <div>
                                        <span>秒</span>
                                        <el-checkbox v-model="formModel.enableAutoRetry">自动重试</el-checkbox>

                                        <!--                                        <el-button type="primary" @click="handleStopRetry" style="margin-left: 10px">
                                                                                    停止重试
                                                                                </el-button>-->
                                    </div>
                                </template>
                            </el-input>
                        </el-form-item>

                        <div class="form-unit">消息订阅</div>
                        <el-form-item label="订阅地址">
                            <el-input v-model="formModel.subscribeUrl" auto-complete="off"
                                      placeholder="请输入订阅地址。例如：/app/hello">
                                <template slot="append">
                                    <div>
                                        <el-button @click="handleSubscribe" type="primary"
                                                   style="color: #FFF; background-color: #409EFF;border-color: #409EFF;"
                                                   :disabled="!connected">加入列表
                                        </el-button>
                                    </div>
                                </template>
                            </el-input>
                        </el-form-item>
                        <el-form-item label="订阅列表">
                            <vxe-table :data="subscribeList" size="mini"
                                       highlight-current-row
                                       stripe border show-overflow
                                       auto-resize
                                       align="center" ref="xTable"
                                       :empty-render="{name: 'EmptyData'}"
                                       resizable :height="180"
                            >
                                <vxe-table-column type="seq" width="50" align="center"/>
                                <vxe-table-column title="操作" width="80">
                                    <template #default="{row,$rowIndex}">
                                        <el-button type="text" @click="handleUnSubscribe(row,$rowIndex)">取消订阅
                                        </el-button>
                                    </template>
                                </vxe-table-column>

                                <vxe-table-column field="url" title="URL"/>
                                <vxe-table-column title="订阅成功" width="80">
                                    <template #default="{row}">
                                        {{ typeof row.subscription == 'object' ? '是' : '否' }}
                                    </template>
                                </vxe-table-column>

                            </vxe-table>
                        </el-form-item>
                        <el-form-item>
                            <div>
                                <el-button @click="handleSubscribeAll" size="mini" :disabled="!connected">订阅全部
                                </el-button>
                                <el-button @click="handleUnSubscribeAll" :disabled="!connected">取消订阅</el-button>
                                <el-button @click="handleSaveSubscribeListToCache">保存缓存</el-button>
                                <el-button @click="handleClearSubscribeListFromCache">清除缓存</el-button>
                            </div>
                        </el-form-item>
                        <div class="form-unit">消息发送</div>

                        <el-row>
                            <el-form-item label="消息路径">
                                <el-input v-model="formModel.msgUrl" auto-complete="off"
                                          placeholder="请输入消息路径，例如：/app/hello"/>
                            </el-form-item>


                            <el-form-item label="消息内容">
                                <el-input v-model="formModel.msgContent" type="textarea"
                                          :autosize="{minRows: 5, maxRows: 5}" auto-complete="off"
                                          placeholder="请输入消息内容"/>
                            </el-form-item>
                            <el-form-item>

                                <el-button @click="handleSendMessage" type="primary" :disabled="!connected">发送
                                </el-button>

                                <!--                                <el-button @click="handleListOnlineUser">在线用户</el-button>-->
                                <el-button @click="logs = ''">清空日志</el-button>

                            </el-form-item>
                        </el-row>
                    </el-col>
                    <el-col :span="14">
                        <div class="form-unit">日志区域</div>

                        <el-row>

                            <log-viewer :log="logs" :height="210 * 3" loading/>

                        </el-row>
                    </el-col>
                </el-row>


            </el-card>

        </el-form>
    </div>
</template>

<script>

import SockJS from 'sockjs-client';
import Stomp from 'stompjs';

import LogViewer from '@femessage/log-viewer'

export default {
    name: "WebSocket",
    components: {
        LogViewer
    },
    data() {
        return {
            formModel: {
                url: 'http://localhost:8080/api/sockjs',
                retryInterval: 10,
                enableAutoRetry: true,
                msgUrl: '/app/hello'
            },
            socketClient: null,
            stompClient: null,
            heartbeatTimer: null,

            connected: false,

            subscribeList: [],
            logs: '',

            reconnectId: null
        }
    },
    computed: {
        cpEnableAutoRetry() {
            return this.formModel.enableAutoRetry
        }
    },
    watch: {
        cpEnableAutoRetry() {
            if (!this.cpEnableAutoRetry) {
                this.handleStopRetry()
            }
        }
    },
    methods: {
        initWebSocket() {
            this.connectWebSocket()

            // 断开重连机制,尝试发送消息,捕获异常发生时重连

        },

// https://stomp-js.github.io/api-docs/latest/classes/Client.html
        connectWebSocket() {
            if (this.reconnectId) {
                clearInterval(this.reconnectId)
                this.reconnectId = null
            }
            let {url} = this.formModel
            if (url.startsWith('ws://') || url.startsWith('wss://')) {
                this.stompClient = Stomp.client(url);
            } else if (url.startsWith('http://') || url.startsWith('https://')) {
                let sockjs = new SockJS(url);
                this.stompClient = Stomp.over(sockjs);
            } else {
                this.appendLog(`服务器地址格式错误，开头为：http、https、ws、wss ...`)
                return
            }
            // 获取STOMP子协议的客户端对象
            // 定义客户端的认证信息,按需求配置
            let headers = {}
            this.stompClient.connect(headers, (frame) => {
                this.connected = true
                this.appendLog(`连接成功...`)

                // 订阅所有
                this.handleSubscribeAll()
            }, (err) => {
                // 连接发生错误时的处理函数

                this.connected = false

                let {enableAutoRetry = false, retryInterval = 10} = this.formModel
                if (enableAutoRetry) {
                    this.appendLog(`连接异常...${JSON.stringify(err)}，${retryInterval}秒后重试`)

                    this.reconnectId = setTimeout(() => {
                        this.connectWebSocket()
                    }, retryInterval * 1000)
                } else {
                    this.appendLog(`连接异常，请稍候重试`)
                }
            });

            this.stompClient.onDisconnect = (e) => {
                this.appendLog(`断开连接...`)
                this.connected = false
            }


            /*sockjs.onopen =  () =>{
                this.appendLog('连接成功...')
                this.stompClient.send('/app/hello', {});
            };

            sockjs.onmessage = (e) => {
                this.appendLog(`收到消息...${e}`)

                sockjs.close();
            };

            sockjs.onclose = () => {
                this.appendLog(`断开连接...`)

            };*/


        },
        disconnectWebSocket() {
            if (this.heartbeatTimer != null) {
                clearInterval(this.heartbeatTimer)
                this.heartbeatTimer = null
            }
            if (this.stompClient != null) {
                this.stompClient.disconnect(() => {
                    this.appendLog(`已主动断开连接...`)
                    this.connected = false
                });
            }

            this.handleStopRetry()

        },
        commonSubscribeMessageCallback(url, msg) {
            this.appendLog(`收到来自 ${url} 的消息，消息内容：${JSON.stringify(msg.body)}`)
        },
        handleSubscribeAll() {
            this.subscribeList.forEach(o => {
                if (!o.subscription) {
                    o.subscription = this.stompClient.subscribe(o.url, (msg) => { // 订阅服务端提供的某个topic
                        this.commonSubscribeMessageCallback(o.url, msg)
                    });
                }
            })
            this.appendLog(`订阅成功...`)
        },
        handleUnSubscribeAll() {
            this.subscribeList.forEach(o => {
                if (o.subscription) {
                    o.subscription.unsubscribe();
                }
            })
            this.subscribeList = []
            this.appendLog(`取消订阅成功...`)

        },
        handleSubscribe() {
            let {subscribeUrl} = this.formModel
            if (this.$strings.isBlank(subscribeUrl)) {
                return
            }
            let subscription = this.stompClient.subscribe(subscribeUrl, (msg) => { // 订阅服务端提供的某个topic
                this.commonSubscribeMessageCallback(subscribeUrl, msg)
            });
            this.subscribeList.push({
                url: subscribeUrl,
                subscription
            })
            this.$set(this.formModel, 'subscribeUrl', '')


        },
        handleUnSubscribe(row, rowIndex) {
            if (row.subscription) {
                row.subscription.unsubscribe();
            }

            this.subscribeList.splice(rowIndex, 1)
        },
        handleSaveSubscribeListToCache() {
            window.localStorage.setItem("websocket-subscribeList", JSON.stringify(this.subscribeList.map(o => {
                return {url: o.url}
            })))
            this.appendLog(`保存订阅列表到本地内存成功...`)
        },
        handleClearSubscribeListFromCache() {
            window.localStorage.removeItem("websocket-subscribeList")
            this.appendLog(`从本地内存清除订阅列表成功...`)

        },
        handleLoadSubscribeListFromCache() {
            let str = window.localStorage.getItem("websocket-subscribeList")
            if (this.$strings.isBlank(str)) {
                this.subscribeList = []
                return
            }
            this.subscribeList = JSON.parse(str)
            this.appendLog(`从本地内存加载订阅列表成功...`)
        },

        handleSendMessage() {
            let {msgUrl, msgContent} = this.formModel
            this.stompClient.send(msgUrl, {}, msgContent);
            this.appendLog(`消息发送成功...`)
        },
        handleListOnlineUser() {
            this.appendLog(`在线用户获取中...`)

            this.$http.get('/websocket/manager/online/users').then(resp => {

                let users = resp.data.data
                this.appendLog(`${JSON.stringify(users)}...`)

            }).finally(() => {
                this.appendLog(`在线用户获取完成...`)
            })
        },

        handleStopRetry() {
            if (this.reconnectId != null) {
                clearTimeout(this.reconnectId)
                this.reconnectId = null
            }
            this.appendLog(`取消自动重试`)

        },
        appendLog(msg, withTime = true) {
            if (withTime) {
                this.logs += `${new Date().format('yyyy-MM-dd HH:mm:ss')} ${msg}\n`
            } else {
                this.logs += `${msg}\n`
            }
        }
    },
    created() {
        this.handleLoadSubscribeListFromCache()
    },
    beforeDestroy: function () {
        // 页面离开时断开连接,清除定时器
        this.disconnectWebSocket();
    },
}
</script>

<style scoped>

</style>
