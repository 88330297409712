<template>
    <i :class="cpClass" :style="cpStyle"></i>
</template>

<script>
export default {
    name: "XIcon",
    props: {
        name: String,
        size: {
            type: Number,
            default: 14
        }
    },
    computed: {
        cpClass() {
            return `xiconfont x-icon-${this.name}`
        },
        cpStyle() {
            return {
                'fontSize': `${this.size}px`
            }
        }
    }
}
</script>

<style scoped>

</style>
