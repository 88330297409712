<template>
    <div>
        <div style="height: 10px"></div>

        <el-card class="box-card" :body-style="{ paddingBottom: '10px' }">
            <div class="form-unit">DPI</div>
            <el-row :gutter="20">
                <el-col :span="8">
                    <el-input v-model.number="formModel.pageWidth" placeholder="纸张宽度">
                        <template #prepend>纸张宽度</template>
                        <template #append>mm</template>
                    </el-input>
                </el-col>
                <el-col :span="8">
                    <el-input v-model.number="formModel.dpi" placeholder="打印机DPI">
                        <template #prepend>DPI</template>
                    </el-input>
                </el-col>
                <el-col :span="8">
                    <el-input v-model.number="formModel.pageDot" placeholder="纸张宽度">
                        <template #prepend>纸张宽度</template>
                        <template #append>px</template>
                    </el-input>
                </el-col>
            </el-row>
            <div class="block"></div>

            <div class="form-unit">PX/UPX</div>
            <el-row :gutter="20">
                <el-col :span="8">
                    <el-input v-model.number="formModel.screenWidth" placeholder="屏幕宽度">
                        <template #prepend>屏幕宽度</template>
                        <template #append>px</template>
                    </el-input>
                </el-col>
                <el-col :span="8">
                    <el-input v-model.number="formModel.pxWidth" placeholder="宽度">
                        <template #prepend>宽度</template>
                        <template #append>px</template>
                    </el-input>
                </el-col>
                <el-col :span="8">
                    <el-input v-model.number="formModel.upxWidth" placeholder="宽度">
                        <template #prepend>宽度</template>
                        <template #append>upx</template>
                    </el-input>
                </el-col>
            </el-row>
<!--
            <div class="form-unit">图片转换</div>
            <el-row>
                <x-file-button title="选择图片" @click="handleChooseFile" accept="*.jpg,*.png"></x-file-button>
            </el-row>-->
        </el-card>
    </div>
</template>

<script>


export default {
    name: "UnitConversion",
    components: {},
    data() {
        return {
            formModel: {
                pageWidth: 60,
                dpi: 200,

                screenWidth: 1920,
                pxWidth: 100
            },

        }
    },
    computed: {
        cpDpiFormStr() {
            let {pageWidth, dpi} = this.formModel
            return pageWidth + '-' + dpi
        },
        cpPxUpxFormStr() {
            let {screenWidth, pxWidth} = this.formModel
            return screenWidth + '-' + pxWidth
        },
    },
    watch: {
        cpDpiFormStr() {
            let pageDot = this.calcPageDot()
            if (!pageDot) {
                return
            }
            this.$set(this.formModel, 'pageDot', pageDot)
        },
        cpPxUpxFormStr() {
            let upxWidth = this.px2Upx()
            if (!upxWidth) {
                return
            }
            this.$set(this.formModel, 'upxWidth', upxWidth)
        }
    },
    methods: {
        calcPageDot() {
            let {pageWidth, dpi} = this.formModel
            if (pageWidth <= 0 || dpi <= 0) {
                return
            }

            // dpi = 每一英寸长度中，取样、可显示或输出点的数目
            // 1 英寸 = 25.4 毫米
            // 以200dpi打印机为例，宽度60mm，换算成英寸为 60 / 25.4 = 2.36，纸张像素点 = 2.36 * 200 = 472

            return Math.round(pageWidth / 25.4 * dpi)
        },

        px2Upx() {
            let {screenWidth, pxWidth} = this.formModel
            if (screenWidth <= 0 || pxWidth <= 0) {
                return
            }
            return Math.round(pxWidth * (750 / screenWidth))
        },


    },
    mounted() {
        let pageDot = this.calcPageDot()
        this.$set(this.formModel, 'pageDot', pageDot)

        let upxWidth = this.px2Upx()
        this.$set(this.formModel, 'upxWidth', upxWidth)

    }
}
</script>

<style lang="scss" scoped>
</style>
