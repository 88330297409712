<template>
    <div>
        <div style="height: 10px"></div>

        <el-card class="box-card" :body-style="{ paddingBottom: '10px' }">

            <el-row :gutter="20">
                <el-col :span="8">
                    <el-input v-model.number="formModel.red" placeholder="红色，取值范围：0~255" :min="0" :max="255">
                        <el-button slot="prepend">红色（R: 0~255）</el-button>
                    </el-input>
                </el-col>
                <el-col :span="8">
                    <el-input v-model.number="formModel.green" placeholder="绿色，取值范围：0~255" :min="0"
                              :max="255">
                        <el-button slot="prepend">绿色（G: 0~255）</el-button>
                    </el-input>
                </el-col>
                <el-col :span="8">
                    <el-input v-model.number="formModel.blue" placeholder="蓝色，取值范围：0~255" :min="0" :max="255">
                        <el-button slot="prepend">蓝色（B: 0~255）</el-button>
                    </el-input>
                </el-col>
            </el-row>
            <div class="block"></div>

            <el-row :gutter="20">
                <el-col :span="8">
                    <el-input v-model.number="formModel.hue" placeholder="色相" >
                        <el-button slot="prepend">色相（H）</el-button>
                    </el-input>
                </el-col>
                <el-col :span="8">
                    <el-input v-model.number="formModel.saturation" placeholder="饱和度" >
                        <el-button slot="prepend">饱和度（S）</el-button>
                    </el-input>
                </el-col>
                <el-col :span="8">
                    <el-input v-model.number="formModel.lightness" placeholder="亮度" >
                        <el-button slot="prepend">亮度（L）</el-button>
                    </el-input>
                </el-col>
            </el-row>
            <div class="block"></div>
        </el-card>
        <div class="block"></div>
        <el-card class="box-card" :body-style="{ paddingBottom: '10px' }">
            <el-row :gutter="20">
                <el-col :span="8">
                    <el-input v-model="formModel.bgColor" placeholder="十六进制颜色" @change="handleBgColorToRgbHsl">
                        <el-button slot="prepend">十六进制颜色</el-button>
                    </el-input>
                </el-col>
                <el-col :span="8">
                    <el-color-picker v-model="formModel.bgColor" @change="handleBgColorToRgbHsl"></el-color-picker>

                </el-col>
            </el-row>
            <div class="block"></div>
            <el-row>
                <div :style="{height: '50px', width: '100%','background-color': formModel.bgColor}"></div>
            </el-row>
        </el-card>
    </div>
</template>

<script>
/*
* https://www.npmjs.com/package/rgb-hex
* https://www.npmjs.com/package/hex-rgb
* */
import rgbHex from 'rgb-hex'
import hexRgb from 'hex-rgb'

export default {
    name: "ColorConversion",
    components: {},
    data() {
        return {
            formModel: {
                red: 255,
                green: 255,
                blue: 255,
                bgColor: '#fff',
                hex: 'fff'  // 十六进制值
            },

        }
    },
    computed: {
        cpRgbStr() {
            let {red, green, blue} = this.getValidRgbFromFormModel()

            return '' + red + green + blue
        },
        cpHslStr() {
            let {hue, saturation, lightness} = this.getValidHslFromFormModel()

            return '' + hue + saturation + lightness
        },
        /*cpBgColor() {
            return this.formModel.bgColor
        }*/
    },
    watch: {
        cpRgbStr() {
            let {red, green, blue} = this.getValidRgbFromFormModel()

            let hex = rgbHex(red, green, blue);
            this.$set(this.formModel, 'bgColor', '#' + hex)
        },
        cpHslStr() {
            let {hue, saturation, lightness} = this.getValidHslFromFormModel()
            let {r, g, b} = this.hsltoRgb(hue, saturation, lightness)
            this.$set(this.formModel, 'red', r)
            this.$set(this.formModel, 'green', g)
            this.$set(this.formModel, 'blue', b)
        }
    },
    methods: {
        getValidRgbValue(value) {
            // 先取最大，限定为最小为 0
            value = Math.max(0, value)
            value = Math.min(255, value)
            return value
        },
        getValidRgbFromFormModel() {
            let {red, green, blue} = this.formModel

            red = this.getValidRgbValue(red)
            green = this.getValidRgbValue(green)
            blue = this.getValidRgbValue(blue)
            return {red, green, blue}
        },
        getValidHslFromFormModel() {
            let {hue, saturation, lightness} = this.formModel

            hue = this.getValidRgbValue(hue)
            saturation = this.getValidRgbValue(saturation)
            lightness = this.getValidRgbValue(lightness)
            return {hue, saturation, lightness}
        },
        handleBgColorToRgbHsl() {
            let bgColor = this.formModel.bgColor
            if (!bgColor.startsWith('#')) {
                bgColor = '#' + bgColor
            }
            // 支持两种格式：#000 #fefefe
            if (bgColor.length != 4 && bgColor.length != 7) {
                return
            }
            let {red, green, blue, alpha} = hexRgb(bgColor)
            this.$set(this.formModel, 'red', red)
            this.$set(this.formModel, 'green', green)
            this.$set(this.formModel, 'blue', blue)
            this.$set(this.formModel, 'alpha', alpha)

            let {h, s, l} = this.rgbtoHsl(red, green, blue)
            this.$set(this.formModel, 'hue', h)
            this.$set(this.formModel, 'saturation', s)
            this.$set(this.formModel, 'lightness', l)
        },
        rgbtoHsl(r, g, b) {
            let min = 0;
            let max = 0;
            const hsl = {H: 0, S: 0, L: 0}
            r = (eval(r) / 51) * .2;
            g = (eval(g) / 51) * .2;
            b = (eval(b) / 51) * .2;

            if (eval(r) >= eval(g))
                max = eval(r);
            else
                max = eval(g);
            if (eval(b) > eval(max))
                max = eval(b);

            if (eval(r) <= eval(g))
                min = eval(r);
            else
                min = eval(g);
            if (eval(b) < eval(min))
                min = eval(b);

            hsl.l = (eval(max) + eval(min)) / 2;
            if (eval(max) == eval(min)) {
                hsl.s = 0;
                hsl.h = 0;
            } else {
                if (hsl.l < .5)
                    hsl.s = (eval(max) - eval(min)) / (eval(max) + eval(min));
                if (hsl.l >= .5)
                    hsl.s = (eval(max) - eval(min)) / (2 - eval(max) - eval(min));
                if (r == max)
                    hsl.h = (eval(g) - eval(b)) / (eval(max) - eval(min));
                if (g == max)
                    hsl.h = 2 + ((eval(b) - eval(r)) / (eval(max) - eval(min)));
                if (b == max)
                    hsl.h = 4 + ((eval(r) - eval(g)) / (eval(max) - eval(min)));
            }
            hsl.h = Math.round(hsl.h * 60);
            if (hsl.h < 0) hsl.h += 360;
            if (hsl.h >= 360) hsl.h -= 360;
            hsl.s = Math.round(hsl.s * 100);
            hsl.l = Math.round(hsl.l * 100);
            return hsl
        },
        findRGB(q1, q2, hue) {
            if (hue > 360) hue = hue - 360;
            if (hue < 0) hue = hue + 360;
            if (hue < 60) return (q1 + (q2 - q1) * hue / 60);
            else if (hue < 180) return (q2);
            else if (hue < 240) return (q1 + (q2 - q1) * (240 - hue) / 60);
            else return (q1);
        },
        hsltoRgb(h, s, l) {
            let p1, p2;
            let tempRGB = {r: 0, g: 0, b: 0}
            l /= 100;
            s /= 100;
            if (l <= 0.5) p2 = l * (1 + s);
            else p2 = l + s - (l * s);
            p1 = 2 * l - p2;
            if (s == 0) {
                tempRGB.r = l;
                tempRGB.g = l;
                tempRGB.b = l;
            } else {
                tempRGB.r = this.findRGB(p1, p2, h + 120);
                tempRGB.g = this.findRGB(p1, p2, h);
                tempRGB.b = this.findRGB(p1, p2, h - 120);
            }
            tempRGB.r *= 255;
            tempRGB.g *= 255;
            tempRGB.b *= 255;
            tempRGB.r = Math.round(tempRGB.r);
            tempRGB.g = Math.round(tempRGB.g);
            tempRGB.b = Math.round(tempRGB.b);
            return tempRGB
        }

    },
    mounted() {
        this.$set(this.formModel, 'bgColor', '#dedede')
        this.handleBgColorToRgbHsl()
    }
}
</script>

<style lang="scss" scoped>
</style>
