<template>
    <baidu-map style="height: 100%" :scroll-wheel-zoom="true" :zoom="zoom"
               :ak="ak"
               MapType="BMAP_SATELLITE_MAP" :center="center"
               @ready="OnMapReady" @click="handleMapClick">
        <bm-view class="map" style="height: 100%"></bm-view>


        <!--                        <bm-control>
                                    <button @click="addZoom(19)">缩放至最大</button>
                                    <button @click="addZoom(10)">还原</button>
                                    <button @click="addZoom(3)">缩放至最小</button>
                                </bm-control>-->


        <!-- 自动填充/关键词搜索 -->
        <bm-control anchor="BMAP_ANCHOR_TOP_LEFT" :offset="{width: 20, height: 20}">
            <bm-auto-complete v-model="formModel.keyword" :sugStyle="{zIndex: 99999}"
                              v-if="showAutoComplete"
                              @confirm="handleSearchItemConfirm">
                <div class="text-field">
                    <label>
                        <div class="label"></div>
                        <div class="input"><input placeholder="关键词" autocomplete="off"></div>
                    </label>
                </div>
            </bm-auto-complete>

            <slot name="control"></slot>
        </bm-control>

        <!-- 城市切换控件 -->
        <bm-city-list anchor="BMAP_ANCHOR_TOP_RIGHT"></bm-city-list>

        <!-- 缩放控件 -->
        <!--        <bm-navigation anchor="BMAP_ANCHOR_TOP_RIGHT" :offset="{width: 10, height: 50}"></bm-navigation>-->

        <!-- 左下角比例尺控件 -->
        <bm-scale anchor="BMAP_ANCHOR_BOTTOM_LEFT"></bm-scale>

        <!-- 右下角定位控件 -->
        <bm-geolocation anchor="BMAP_ANCHOR_BOTTOM_RIGHT" :showAddressBar="true" v-show="showLocate"
                        :autoLocation="autoLocate" @locationSuccess="handleLocateSuccess"></bm-geolocation>

        <!-- 中心点 -->
        <!--
                                <bm-marker :position="center" :dragging="true" animation="BMAP_ANIMATION_BOUNCE"
                                           :icon="{url: 'http://developer.baidu.com/map/jsdemo/img/fox.gif', size: {width: 300, height: 157}}"></bm-marker>
        -->

        <bm-marker v-for="(marker,index) in markerOverlayList" :key="marker.uuid" :position="marker.position"
                   :dragging="marker.dragging" :label="marker.label" :icon="marker.icon" @click="marker.click">

        </bm-marker>

        <bm-circle v-for="(circle,index) in circleOverlayList" :key="circle.uuid"
                   :center="circle.center" :radius="circle.radius" stroke-color="blue" :stroke-opacity="0.5"
                   :stroke-weight="2" @lineupdate="circle.lineupdate" :editing="circle.editing"></bm-circle>


        <bml-lushu v-for="(lushu,index) in lushuList" :key="lushu.uuid"
                   @stop="lushu.stop"
                   :path="lushu.path"
                   :icon="lushu.icon"
                   :play="lushu.play"
                   :rotation="lushu.rotation">
        </bml-lushu>


        <bml-curve-line v-for="(curve,index) in curveLineList" :key="curve.uuid"
                        :points="curve.points" :editing="curve.editing" @lineupdate="curve.lineupdate"></bml-curve-line>


        <bm-driving
            v-for="(driving,index) in drivingList" :key="driving.uuid"
            :start="driving.start"
            :end="driving.end" stroke-color="blank"
            :auto-viewport="driving.autoViewport"
            :panel="driving.panel"
            :waypoints="driving.waypoints"
            @polylinesset="driving.polylinesset"
            @markersset="driving.markersset"
            @searchcomplete="driving.searchcomplete"
        ></bm-driving>

        <bm-info-window v-for="(infoWindow,index) in infoWindowList" :key="infoWindow.uuid"
                        :position="infoWindow.point" :title="infoWindow.title" :show="infoWindow.show"
                        :closeOnClick="infoWindow.closeOnClick">
            <p v-html="infoWindow.contents"></p>
        </bm-info-window>

        <bm-polyline v-for="(polyline,index) in polylineList" :key="polyline.uuid" :path="polyline.path"
                     :stroke-color="polyline.strokeColor" :stroke-opacity="polyline.strokeOpacity"
                     :stroke-weight="polyline.strokeWeight" :editing="polyline.editing"
                     @lineupdate="polyline.lineupdate"></bm-polyline>

    </baidu-map>
</template>

<script>

import {BmlLushu} from 'vue-baidu-map'
import {BmlCurveLine} from 'vue-baidu-map'

import DistanceTool from 'bmaplib.distancetool'

/*
* 百度地图API列表： https://mapopen-pub-jsapi.bj.bcebos.com/jsapi/reference/jsapi_webgl_1_0.html#a1b3
*
*
* icon: {
    url: '/static/images/end-point.png',
    size: {width: 128, height: 128},        要显示的图片大小（偏移，只显示这一块区域）
    opts: {
        imageSize: {width: 48, height: 48},     实际显示的图片大小（会对图片部分进行缩小）
        anchor: {width: 40, height: 40}         针对图片左上角的偏移
    }
* }
* */

export default {
    name: "XMap",
    components: {
        BmlLushu,
        BmlCurveLine
    },
    props: {
        ak: {
            type: [String],
        },
        zoom: {
            type: Number,
            default: () => 15           // 地图级别，https://lbsyun.baidu.com/jsdemo.htm#bSetGetMapZoom
        },
        center: {
            type: [String, Object],     // 定位, 可使用如“广州市海珠区”的地区字符串，也可以使用对象如 {lng: 116.404, lat: 39.915} 表示经纬度
            /*default: () => {
                return {
                    lng: 116.331398,    // 默认为北京
                    lat: 39.897445
                }
            }*/
        },
        autoLocate: {           // 是否自动定位
            type: Boolean,
            default: () => true
        },
        showAutoComplete: {     // 是否显示地址搜索框
            type: Boolean,
            default: () => true
        },
        showLocate: {     // 是否显示定位框
            type: Boolean,
            default: () => true
        },
    },
    data() {
        return {


            map: null,
            formModel: {},
            geolocation: null,

            circleOverlayList: [],      // 圆形覆盖物列表,
            markerOverlayList: [],      // 点覆盖物列表,

            lushuList: [],            // 路书列表
            curveLineList: [],          // 弧线列表
            drivingList: [],          // 驾车路线规划列表
            infoWindowList: [],         // 信息窗口, 好像有一点问题

            polylineList: [],              // 折线列表

            distanceTool: null
        }
    },
    computed: {},
    methods: {
        getCenterPoint() {
            return this.map.getCenter()
        },
        centerAndZoom(point, zoom) {
            // {lng: xx,  lat: xx}
            let bpoint = new BMap.Point(point.lng, point.lat);  // 创建点坐标

            this.map.centerAndZoom(bpoint, zoom || this.zoom);
        },
        clearAll() {
            this.clearMarker()
            this.clearCircle()
            this.clearLushu()
            this.clearCurveLine()
            this.clearDriving()
            this.clearInfoWindow()
            this.clearPolyline()
        },
        clearMarker() {
            this.markerOverlayList = []
        },
        /**
         * 画点覆盖物
         * https://dafrok.github.io/vue-baidu-map/#/zh/overlay/marker
         * @param data {position: Point, dragging: Boolean, icon: Object, label: Label  }
         */
        drawMarker(data) {
            data.uuid = this.generateUUID()

            if (!data.click) {
                data.click = (e) => {

                }
            }

            this.markerOverlayList.push(data)
        },
        clearCircle() {
            this.circleOverlayList = []
        },
        /**
         * 画圆覆盖物
         * https://dafrok.github.io/vue-baidu-map/#/zh/overlay/circle
         * @param data {center: Point, radius: Number}
         */
        drawCircle(data) {
            if (!data.lineupdate) {
                data.lineupdate = (e) => {
                    data.center = e.target.getCenter()
                    data.radius = e.target.getRadius()
                }
            }
            data.uuid = this.generateUUID()

            /*var circle = new BMapGL.Circle(new BMapGL.Point(116.404, 39.915), 500, {
                strokeColor: 'blue',
                strokeWeight: 2,
                strokeOpacity: 0.5
            });
            map.addOverlay(circle);
*/
            this.circleOverlayList.push(data)
        },


        clearLushu() {
            this.lushuList = []
        },
        /**
         * 画路书
         * https://dafrok.github.io/vue-baidu-map/#/zh/bmaplib/lushu
         * @param data {
         *      play: Boolean, path: Array[{lng, lat}], landmarkPois: Array[{lng, lat, html, pauseTime}], icon: Icon,
         *      speed: Number, autoView: Boolean, rotation: Boolean, infoWindow: Boolean, content: String,
         *      start: Function, pause: Function, stop: Function,
         * }
         */
        drawLushu(data) {
            if (!data.start) {
                data.start = (e) => {
                }
            }
            if (!data.stop) {
                data.stop = (e) => {
                }
            }
            if (!data.pause) {
                data.pause = (e) => {
                }
            }
            data.uuid = this.generateUUID()
            this.lushuList.push(data)
        },

        clearCurveLine() {
            this.curveLineList = []
        },
        /**
         * 画弧线
         * https://dafrok.github.io/vue-baidu-map/#/zh/bmaplib/curve-line
         * @param data {
         *      points: Array[Point], strokeColor: String, strokeWeight: Number, strokeOpacity: Number, strokeStyle: String,
         *      massClear: Boolean, editing: Boolean, clicking: Boolean
         * }
         */
        drawCurveLine(data) {
            if (!data.lineupdate) {
                data.lineupdate = (e) => {
                    data.points = e.target.cornerPoints
                }
            }
            data.uuid = this.generateUUID()
            this.curveLineList.push(data)
        },
        clearDriving() {
            this.drivingList = []
        },
        /**
         * 画驾车路线规划
         * https://dafrok.github.io/vue-baidu-map/#/zh/search/driving
         * @param data {
         *      points: Array[Point], strokeColor: String, strokeWeight: Number, strokeOpacity: Number, strokeStyle: String,
         *      massClear: Boolean, editing: Boolean, clicking: Boolean
         * }
         */
        drawDriving(data) {
            if (!data.polylinesset) {
                data.polylinesset = (e) => {

                }
            }
            if (!data.markersset) {
                data.markersset = (e) => {
                }
            }
            if (!data.searchcomplete) {
                data.searchcomplete = (e) => {
                }
            }
            data.uuid = this.generateUUID()
            this.drivingList.push(data)
        },
        clearPolyline() {
            this.polylineList = []
        },
        /**
         * 画折线
         * https://dafrok.github.io/vue-baidu-map/#/zh/overlay/polyline
         * @param data {
         *
         * }
         */
        drawPolyline(data) {
            if (!data.lineupdate) {
                data.lineupdate = (e) => {

                }
            }

            data.uuid = this.generateUUID()
            this.polylineList.push(data)
        },
        clearInfoWindow() {
            this.infoWindowList = []
        },

        /**
         * 添加一个信息窗体
         * https://dafrok.github.io/vue-baidu-map/#/zh/overlay/info-window
         * @param data {
         *      show： Boolean, position: Point, width: Number, height: Number, maxWidth: Number, offset: Size, title: String
         *      autoPan: Boolean, closeOnClick: Boolean, message: String,maximize: Boolean
         * }
         */
        drawInfoWindow(data) {

            data.uuid = this.generateUUID()
            this.infoWindowList.push(data)
        },
        openDistanceTool() {
            const {distanceTool} = this
            distanceTool && distanceTool.open()
        },

        generateUUID() {
            let d = new Date().getTime();
            if (window.performance && typeof window.performance.now === "function") {
                d += performance.now(); //use high-precision timer if available
            }
            let uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
                let r = (d + Math.random() * 16) % 16 | 0;
                d = Math.floor(d / 16);
                return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
            });
            return uuid;
        },
        handleMapClick(e) {    //点击地图获取一些信息，

            let geocoder = new BMap.Geocoder();  //创建地址解析器的实例
            geocoder.getLocation(e.point, gr => {
                // console.log(gr)
                // gr： GeolocationResult  https://mapopen-pub-jsapi.bj.bcebos.com/jsapi/reference/jsapi_webgl_1_0.html#a8b41
                this.$emit('click', gr)
            });
        },


        OnMapReady({BMap, map}) {
            // 下面注释是百度地图API官方实现方法

            let geolocation = new BMap.Geolocation();
            this.geolocation = geolocation
            this.map = map
            this.BMap = BMap
            this.locateCurrentLocation()

            this.distanceTool = new DistanceTool(map, {lineStroke: 2})

            this.$emit('mapReady', {BMap, map})
        },
        handleLocateSuccess(rs) {
            // console.log('handleLocateSuccess', rs)
            let geocoder = new BMap.Geocoder();  //创建地址解析器的实例
            geocoder.getLocation(rs.point, gr => {
                this.map.centerAndZoom(rs.point, this.zoom);
                this.$emit('click', gr)
            })
        },


        handleSearchItemConfirm({type, target, item}) {
            this.map.clearOverlays();    //清除地图上所有覆盖物

            let local = new BMap.LocalSearch(this.map, { //智能搜索
                onSearchComplete: () => {
                    let pp = local.getResults().getPoi(0).point;    //获取第一个智能搜索的结果
                    this.map.centerAndZoom(pp, this.zoom);

                    let geocoder = new BMap.Geocoder();  //创建地址解析器的实例
                    geocoder.getLocation(pp, gr => {
                        this.$emit('click', gr)
                    })
                }
            });

            local.search(this.formModel.keyword);
        },

        locateCurrentLocation() {
            if (this.center != null) {
                return
            }

            // 开启SDK辅助定位
            this.geolocation.enableSDKLocation();
            this.geolocation.getCurrentPosition((r) => {
                //getStatus拿到的是状态信息，失败与否
                if (this.geolocation.getStatus() == BMAP_STATUS_SUCCESS) {

                    this.map.centerAndZoom(r.point, this.zoom);

                    // 格式和其他的不一致，所以再解析一下
                    let geocoder = new BMap.Geocoder();  //创建地址解析器的实例
                    geocoder.getLocation(r.point, gr => {
                        this.$emit('click', gr)
                    })
                } else {
                    console.error("位置信息获取失败，" + this.map.getStatus());
                }
            }, {
                enableHighAccuracy: true, //要求浏览器获取最佳结果
            })
        },
        unmount() {
            this.distanceTool && this.distanceTool.close()
        },
    }
}
</script>

<style lang="scss" scoped>

.text-field {
    box-sizing: border-box;
    font-size: 0;
    display: inline-block;
    width: 100%;

    .input {
        font-size: 0;
        overflow: hidden;
        box-sizing: border-box;
        border: 1px solid #36c;
        box-shadow: inset 0 0 2px silver;
        background: #fff;
        height: 2rem;
        line-height: 2rem;
    }


    input {
        box-sizing: border-box;
        padding: 0 0.5rem;
        width: 100%;
        margin: 0;
        border: 0;
        outline: 0;
        height: 100%;
        background: transparent;
    }
}

::v-deep .BMap_noprint.anchorTL{
    z-index: 99999;
}
</style>
