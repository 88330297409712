// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import App from './App'
import router from './router'

Vue.config.productionTip = false

import './assets/css/common.scss'


import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
// import '@/assets/css/element-variables.scss'

Vue.use(ElementUI, {size: 'mini', zIndex: 3000})


import 'xe-utils'
import VXETable from 'vxe-table'
Vue.use(VXETable, {
    size: 'small'
})

import 'vxe-table/lib/index.css'


import axios from "@/utils/axios"
Vue.prototype.$http = axios

import dates from "@/utils/dates.js"
import strings from "@/utils/strings.js"
import clipboard from '@/utils/clipboard'

Vue.prototype.$dates = dates
Vue.prototype.$strings = strings
Vue.prototype.$clipboard = clipboard



import './extends/index'
import './directives/index'

import BaiduMap from 'vue-baidu-map'
Vue.use(BaiduMap, {
    // ak 是在百度地图开发者平台申请的密钥 详见 http://lbsyun.baidu.com/apiconsole/key */
    // ak: '58ODRNayvRyEtZlZqSM879GNl7j0Kwtn'
})


/* eslint-disable no-new */
new Vue({
  el: '#app',
  router,
  components: { App },
  template: '<App/>'
})
