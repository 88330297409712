

const strings = {
    isEmpty: function (str) {
        return str === undefined || str === null || str === '';
    },
    isNotEmpty: function (str) {
        return !this.isEmpty(str);
    },
    isBlank: function (str) {
        return str === undefined || str === null || /^\s*$/.test(str);
    },
    isNotBlank: function (str) {
        return !this.isBlank(str);
    },
    trim: function (str) {
        return str.replace(/^\s+|\s+$/, '');
    },
    // trim(str){ //删除左右两端的空格
    //     return str.replace(/(^\s*)|(\s*$)/g, "");
    // },
    ltrim: function(str){ //删除左边的空格
        return str.replace(/(^\s*)/g,"");
    },
    rtrim: function(str){ //删除右边的空格
        return str.replace(/(\s*$)/g,"");
    },
    trimByChar: function (str, char, type) {
        if (char) {
          if (type == 'left') {
            return str.replace(new RegExp('^\\'+char+'+', 'g'), '');
          } else if (type == 'right') {
            return str.replace(new RegExp('\\'+char+'+$', 'g'), '');
          }
          return str.replace(new RegExp('^\\'+char+'+|\\'+char+'+$', 'g'), '');
        }
        return str.replace(/^\s+|\s+$/g, '');
    },
    trimToEmpty: function (str) {
        return (str === undefined || str === null) ? "" : this.trim(str);
    },
    startsWith: function (str, prefix) {
        return str.indexOf(prefix) === 0;
    },
    endsWith: function (str, suffix) {
        return str.lastIndexOf(suffix) === 0;
    },
    //在字符串的左边填充一些特定的字符
    lpad: function (len, s, targetStr) {
        var a = new Array(targetStr);
        var n = (len - targetStr.length);
        for (var i = 0; i < n; i++) {
            a.unshift(s);
        }
        return a.join("");
    },
    //在字符串的右边填充一些特定的字符
    rpad: function (len, s, targetStr) {
        var a = new Array(targetStr);
        var n = (len - targetStr.length);
        for (var i = 0; i < n; i++) {
            a.push(s);
        }
        return a.join("");
    },
    cap: function (str) {
        if(this.isBlank(str)){
            return str
        }
        return str.substring(0, 1).toUpperCase() + str.substring(1)
    },
    defaultString: function(str,defaultStr){

        return (str === undefined || str === null) ? defaultStr : str
    },
    defaultIfBlank: function(str,defaultStr){

        return this.isBlank(str) ? defaultStr : str
    },
    randomStr(length = 1) {
        return Math.random().toString(36).substr(2,2 + length)
    }
}

export default strings
