<template>
    <div>
        <el-card class="box-card">
            <el-form label-width="70px" @submit.native.prevent size="small">

                <el-row>
                    <el-col :span="8">
                        <el-form-item label="生成方式">
                            <el-radio-group v-model="formModel.generateMode">
                                <el-radio label="single" border>单个生成</el-radio>
                                <el-radio label="batch" border>批量生成</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </el-col>

                    <el-col :span="8">
                        <el-form-item label="条码类型">
                            <el-radio-group v-model="formModel.codeType">
                                <el-radio label="barcode" border>条形码</el-radio>
                                <el-radio label="qrcode" border>二维码</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </el-col>
                    <el-col :span="4">
                        <el-form-item label="间隔宽度">
                            <el-input v-model="formModel.spaceWidth" placeholder="显示条码间隔"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="4">
                        <el-form-item label="高度">
                            <el-input v-model="formModel.height" placeholder="一维码高度/二维码高度宽度"></el-input>
                        </el-form-item>
                    </el-col>

                </el-row>


                <el-row>
                    <el-col :span="24">

                        <el-form-item label="条码内容">
                            <el-input
                                    type="textarea"
                                    :rows="7"
                                    placeholder="请输入内容"
                                    v-model="formModel.content" resize="none">
                            </el-input>
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-row>
                    <el-col :span="24">
                        <el-form-item>
                            <el-button type="primary" @click="handleGenerate">生成条码</el-button>
                            <el-button @click="handleClear">清空</el-button>
                        </el-form-item>
                    </el-col>
                </el-row>

            </el-form>
        </el-card>
        <div style="height: 10px"></div>
        <el-card class="box-card code-container">
            <div v-if="codeValues.length > 0 && formModel.codeType == 'qrcode'">
                <div v-for="(value,index) in codeValues" :key="index" :style="cpCodeWrapperStyle">
                    <vue-qr :text="value" :size="formModel.height"></vue-qr>
                </div>
            </div>

            <div v-if="codeValues.length > 0 && formModel.codeType == 'barcode'">
                <div v-for="(value,index) in codeValues" :key="index" :style="cpCodeWrapperStyle">
                    <vue-barcode :value="value" :options="{ displayValue: true, height: formModel.height }"></vue-barcode>
                </div>
            </div>
        </el-card>
    </div>


</template>

<script>

    import VueQr from 'vue-qr'
    import VueBarcode from '@chenfengyuan/vue-barcode'


    export default {
        name: "CodeGenerate",
        components: {
            VueQr,
            VueBarcode
        },
        data() {
            return {
                formModel: {
                    generateMode: 'batch',
                    codeType: 'qrcode',
                    content: '',
                    height: 200,
                    spaceWidth: 20
                },
                codeValues: []
            }
        },
        computed: {
            cpCodeWrapperStyle() {
                let {spaceWidth} = this.formModel
                return {
                    'display': 'inline-block',
                    'margin-right': spaceWidth + 'px'
                }
            }
        },
        methods: {
            handleGenerate() {
                let {generateMode, codeType, content} = this.formModel
                if (generateMode == 'batch') {
                    this.codeValues = content.split(/[(\r\n)\r\n]+/).filter(value => value.trim() != '')

                } else {
                    this.codeValues = [content]
                }
            },
            handleClear() {
                this.codeValues = []
            }
        }
    }
</script>

<style scoped>

    .code-container {
        width: 100%;
        height: 46vh;
        overflow-y: auto;
    }

    .code-wrapper {
        display: inline-block;
        margin-right: 10px
    }

    .el-radio {
        margin-right: 10px;
    }
</style>
