<template>
    <div>
        <div style="height: 10px"></div>
        <el-card class="box-card" :body-style="{ paddingBottom: '10px' }">

            <el-form inline @submit.native.prevent size="mini">
                <el-row>
                    <el-form-item label="当前坐标">
                        <el-input v-model="formModel.coordinate" auto-complete="off" maxlength='30'
                                  placeholder="未获取">
                            <template slot="append">
                                <div>
                                    <el-select v-model="formModel.coordinateType" placeholder="坐标类型"
                                               style="width: 180px;">
                                        <el-option label="BD09LL(百度经纬度)" value="bd09ll"></el-option>
                                        <el-option label="WGS84(大地坐标系)" value="wgs84"></el-option>
                                        <el-option label="GCJ02(火星坐标系)" value="gcj02"></el-option>
                                        <el-option label="BD09MC(百度墨卡托米制)" value="bd09mc"></el-option>
                                    </el-select>
                                    <el-button type="primary"
                                               style="margin-left: 10px;color: #FFF; background-color: #409EFF;border-color: #409EFF;"
                                               @click="handleLocate">定位
                                    </el-button>
                                </div>
                            </template>
                        </el-input>
                    </el-form-item>
                    <el-form-item label="地址">
                        <el-input v-model="currentLocation.address" auto-complete="off" maxlength='30'
                                  placeholder="未获取"/>
                    </el-form-item>
                    <el-form-item>
                        <el-button @click="$refs.xmap.openDistanceTool()">开启测距</el-button>
                        <el-button @click="handleTransferCoordinate">坐标转换</el-button>
                        <el-button @click="$refs.xmap.clearAll()">清空覆盖物</el-button>
                    </el-form-item>
                </el-row>

                <el-alert type="info" :closable="false" >
                    <el-row>
                        BD09LL(百度经纬度)：  <el-link type="primary" @click="$clipboard(formModel.bd09ll_coordinate,$event)">{{formModel.bd09ll_coordinate}}</el-link>；
                        WGS84(大地坐标系)：<el-link type="primary" @click="$clipboard(formModel.wgs84_coordinate,$event)">{{formModel.wgs84_coordinate}}</el-link>
                    </el-row>
                    <el-row>
                        GCJ02(火星坐标系)：<el-link type="primary" @click="$clipboard(formModel.gcj02_coordinate,$event)">{{formModel.gcj02_coordinate}}</el-link>；
                        BD09MC(百度墨卡托米制)：<el-link type="primary" @click="$clipboard(formModel.bd09mc_coordinate,$event)">{{formModel.bd09mc_coordinate}}</el-link>
                    </el-row>
                </el-alert>
            </el-form>
        </el-card>

        <div style="height: 10px"></div>
        <el-card class="box-card ">
            <x-map ref="xmap" @click="handleMapClick" :zoom="18" :auto-locate="true" style="height: 60vh"></x-map>

        </el-card>

    </div>
</template>

<script>

import gcoord from 'gcoord';

export default {
    name: "BaiduMap",
    components: {},
    data() {
        return {
            formModel: {
                coordinateType: 'bd09ll',
            },

            currentLocation: {},

            crsMap:{
                'bd09ll': gcoord.BD09LL,
                'bd09mc': gcoord.BD09MC,
                'wgs84': gcoord.WGS84,
                'gcj02': gcoord.GCJ02,
            }
        }
    },
    computed: {},
    mounted() {

    },

    methods: {
        handleLocate() {
            let {coordinate, coordinateType} = this.formModel
            if (this.$strings.isBlank(coordinate)) {
                return
            }

            let arr = coordinate.split(',')
            if (arr.length != 2) {
                return
            }

            let longitude = Number.parseFloat(arr[0])
            let latitude = Number.parseFloat(arr[1])

            // 坐标系对应
            if(coordinateType != 'bd09ll') {
                let currCrs = this.crsMap[coordinateType]
                let result = gcoord.transform(
                    [longitude, latitude],    // 经纬度坐标
                    currCrs,               // 当前坐标系
                    this.crsMap['bd09ll']                 // 目标坐标系
                );
                longitude = Number.parseFloat(result[0])
                latitude = Number.parseFloat(result[1])
            }

            let point  = {
                lng: longitude,
                lat: latitude
            }
            this.$refs.xmap.centerAndZoom(point)

            this.$refs.xmap.clearAll()
            this.$refs.xmap.drawMarker({
                position: point
            })

            this.handleTransferCoordinate()
        },
        handleTransferCoordinate() {
            this.$set(this.formModel, 'transformed', false)

            let {coordinate, coordinateType} = this.formModel
            if (this.$strings.isBlank(coordinate)) {
                return
            }

            let arr = coordinate.split(',')
            if (arr.length != 2) {
                return
            }

            let longitude = arr[0]
            let latitude = arr[1]

            // 坐标系对应
            let currCrs = this.crsMap[coordinateType]

            Object.keys(this.crsMap).forEach(key => {
                let result = gcoord.transform(
                    [longitude, latitude],    // 经纬度坐标
                    currCrs,               // 当前坐标系
                    this.crsMap[key]                 // 目标坐标系
                );

                let tlongitude = Number.parseFloat(result[0]).toFixed(6)
                let tlatitude = Number.parseFloat(result[1]).toFixed(6)

                this.$set(this.formModel, `${key}_longitude`, tlongitude)
                this.$set(this.formModel, `${key}_latitude`, tlatitude)
                this.$set(this.formModel, `${key}_coordinate`, `${tlongitude},${tlatitude}`)
            })


            this.$set(this.formModel, 'transformed', true)
        },
        handleMapClick(gr) {

            gr.addressComponents = gr.addressComponents || {}
            let {addressComponents: {province, city, district, street, streetNumber}, surroundingPois = []} = gr

            let {lng, lat} = gr.point
            this.currentLocation = {
                lng: lng,
                lat: lat,
            }

            this.$set(this.formModel, 'coordinate', `${lng},${lat}`)
            this.$set(this.formModel, 'coordinateType', `bd09ll`)
            this.handleTransferCoordinate()

            if (surroundingPois.length > 0) {
                this.currentLocation.address = `${surroundingPois[0].address}${surroundingPois[0].title}`
            } else {
                this.currentLocation.address = `${province}${city}${district}${street}${streetNumber}`
            }

            this.$refs.xmap.clearAll()

            this.$refs.xmap.drawMarker({
                position: gr.point
            })
        },
    }
}
</script>

<style scoped>

</style>
