<template>
    <x-draggable-dialog :title="cpDialogTitle" :visible.sync="dialogVisible" :show-close="false">
        <div>
            <div class="form-unit">基本设置</div>
            <base-setting :modeler="modeler" :element="element"></base-setting>
        </div>

        <div>
            <div class="form-unit">审核</div>
            <form-setting :modeler="modeler" :element="element"></form-setting>
        </div>
        <div>
            <div class="form-unit">任务监听</div>
            <task-listener-setting :modeler="modeler" :element="element"></task-listener-setting>
        </div>
<!--        <el-collapse v-model="activeNames" style="width: 100%" class="collapse-wrapper">
            <el-collapse-item title="基本设置" name="base-setting" class="collapse-wrapper-item">
                <template #title>
                    <i class="header-icon el-icon-info"></i> 基本设置
                </template>
                <base-setting :modeler="modeler" :element="element"></base-setting>
            </el-collapse-item>
            <el-collapse-item title="任务监听" name="task-listen" class="collapse-wrapper-item">
                <template #title>
                    <i class="header-icon el-icon-headset"></i> 任务监听
                </template>
                <task-listener-setting :modeler="modeler" :element="element"></task-listener-setting>
            </el-collapse-item>
            <el-collapse-item title="执行监听" name="execution-listen" class="collapse-wrapper-item">
                <template #title>
                    <i class="header-icon el-icon-headset"></i> 执行监听
                </template>
                <div>清晰明确：语言表达清晰且表意明确，让用户快速理解进而作出决策；</div>
                <div>帮助用户识别：界面简单直白，让用户快速识别而非回忆，减少用户记忆负担。</div>
            </el-collapse-item>
            <el-collapse-item title="审核" name="audit" class="collapse-wrapper-item">
                <template #title>
                    <i class="header-icon el-icon-user"></i> 审核
                </template>
            </el-collapse-item>
            <el-collapse-item title="表单" name="form" class="collapse-wrapper-item">
                <template #title>
                    <i class="header-icon el-icon-notebook-1"></i> 表单
                </template>

                <form-setting :modeler="modeler" :element="element"></form-setting>
            </el-collapse-item>
        </el-collapse>-->
    </x-draggable-dialog>

    <!--    <el-drawer
            title="我是标题"
            :visible="element != null"
            direction="rtl"
            :append-to-body="false"
            width="100%"
            :show-close="false"
            :withHeader="false"
            custom-class="x-drawer"
            :modal="false">

        </el-drawer>-->


</template>

<script>
import BaseSetting from "@/extends/x-bpmn/panel/base-setting";
import FormSetting from "@/extends/x-bpmn/panel/form-setting";
import TaskListenerSetting from "@/extends/x-bpmn/panel/task-listener-setting";

export default {
    name: "XBpmnPropertyPanel",
    components: {TaskListenerSetting, FormSetting, BaseSetting},
    props: {
        modeler: null
    },
    data() {
        return {
            element: null,
            activeNames: ['base-setting'],

            dialogVisible: false
        }
    },
    computed: {
        cpDialogTitle() {
            let elementType = this.element?.type || 'bpmn:Process'
            if (elementType == 'bpmn:Process') {
                return '流程详情'
            }
            if (/bpmn:(.*?)Task/ig.test(elementType) ) {
                return '任务详情'
            }
            if (/bpmn:(.*?)Event/ig.test(elementType)) {
                return '事件详情'
            }
            return '节点详情'
        }
    },
    watch: {
        element() {
            this.dialogVisible = this.element != null
        }
    },

    methods: {
        getTitle() {

        },
        isShowFormSetting() {
            if (!this.element) {
                return false
            }
            if (['bpmn:UserTask', 'bpmn:StartEvent', 'bpmn:EndEvent'].includes(this.element.type)) {
                return true
            }
            const bo = this.element?.businessObject
            return bo?.sourceRef?.$type === 'bpmn:UserTask' && !!bo?.conditionExpression
        },

        handleModeler() {
            /*this.modeler.on('root.added', e => {
                if (e.element.type === 'bpmn:Process') {
                    this.element = null
                    this.$nextTick().then(() => {
                        this.element = e.element
                    })
                }
            })*/
            const eventTypes = ['element.click', 'element.changed'] // 需要监听的事件集合

            this.modeler.on('element.click', e => {
                const {element} = e
                if (element.type === 'bpmn:Process') {
                    this.element = element
                }
            })
            this.modeler.on('selection.changed', e => {
                // hack 同类型面板不刷新
                const element = e.newSelection[0]
                if (element) {
                    this.element = element
                }
            })
        }
    },
    mounted() {
        this.handleModeler()
    }
}
</script>

<style lang="scss" scoped>
.container {
    padding: 10px;
    overflow: auto;
}

.collapse-wrapper {
    .collapse-wrapper-item {
        ::v-deep .header-icon {
            padding: 0px 5px;
        }
    }
}

::v-deep .el-drawer__wrapper {
    position: absolute;
    width: 400px;
    height: 100%;
}


::v-deep .x-drawer {
    width: 100% !important;
    border-left: 1px solid #409EFF;
}

::v-deep .el-drawer {
    width: 100% !important;
}

::v-deep .el-drawer__body {
    padding: 10px;
}
</style>
