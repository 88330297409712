function isDecimal(value) {
    let rep = /[\.]/;
    return rep.test(value)
}

export function commonParse(element) {
    const result = {
        ...element
    }

    Object.keys(result).forEach(key => {
        let value = result[key]
        if(key == 'scaleX' || key == 'scaleY') {
            result[key] = Number.parseFloat(value.toFixed(2))
            return
        }

        if (typeof value === 'number' && isDecimal(value)) {
            result[key] = Math.floor(value)
        }
    })

    /*result.width *= result.zoomX
    result.height *= result.zoomY*/

    return result
}

