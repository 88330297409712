<template>
    <div>
        <el-card class="box-card">
            <el-form label-width="70px" @submit.native.prevent size="small">
                <el-form-item label="内容">
                    <el-input
                        type="textarea"
                        :rows="13"
                        placeholder="请输入内容"
                        v-model="formModel.content" resize="none" show-word-limit>
                    </el-input>
                </el-form-item>

                <el-row>
                    <el-form-item>
                        <el-button @click="handleGzip">GZIP压缩</el-button>
                        <el-button @click="handleBase64Encode">Base64编码</el-button>
                        <el-button @click="handleEncodeURI ">EncodeURI</el-button>
                        <el-button @click="handleEncodeURIComponent ">EncodeURIComponent</el-button>
                        <el-divider direction="vertical"></el-divider>
                        <el-button @click="handleRsaEncrypt">RSA加密</el-button>
                        <el-button @click="handleAesEncrypt">AES加密</el-button>

                        <el-divider direction="vertical"></el-divider>
                        <el-button @click="handleMd5 ">MD5</el-button>
                        <el-button @click="handleSha256Encrypt ">SHA256加密</el-button>
                        <el-divider direction="vertical"></el-divider>

                        <el-button @click="handleOpenSettingDialog" type="info" icon="el-icon-setting">设置</el-button>
                        <el-button @click="handleClear" type="danger">清空</el-button>
                    </el-form-item>
                </el-row>
                <el-row>
                    <el-form-item>
                        <el-button @click="handleUnGzip">GZIP解压</el-button>
                        <el-button @click="handleBase64Decode">Base64解码</el-button>
                        <el-button @click="handleDecodeURI ">DecodeURI</el-button>
                        <el-button @click="handleDecodeURIComponent ">DecodeURIComponent</el-button>
                        <el-divider direction="vertical"></el-divider>
                        <el-button @click="handleRsaDecrypt">RSA解密</el-button>
                        <el-button @click="handleAesDecrypt">AES解密</el-button>

                        <el-divider direction="vertical"></el-divider>

                    </el-form-item>
                </el-row>
            </el-form>
        </el-card>
        <div style="height: 10px"></div>
        <el-card class="box-card code-container" :body-style="{ paddingBottom: '0px' }">
            <el-form label-width="70px" @submit.native.prevent size="small">

                <el-form-item label="结果">
                    <el-input
                        type="textarea"
                        :rows="13"
                        placeholder="结果"
                        v-model="formModel.result" resize="none" show-word-limit>
                    </el-input>
                </el-form-item>
            </el-form>

        </el-card>


        <el-dialog
            title="设置"
            :visible.sync="settingDialogVisible"
            width="40%">
            <div>
                <el-form label-width="80px">
                    <el-form-item label="RSA私钥">
                        <el-input type="textarea" :autosize="{ minRows: 3, maxRows: 4}"
                                  v-model="settingFormModel.rsaPrivateKey"></el-input>
                    </el-form-item>
                    <el-form-item label="RSA公钥">
                        <el-input type="textarea" :autosize="{ minRows: 3, maxRows: 4}"
                                  v-model="settingFormModel.rsaPublicKey"></el-input>
                    </el-form-item>
                    <el-form-item label="AES密钥">
                        <el-input type="textarea" :autosize="{ minRows: 3, maxRows: 4}"
                                  v-model="settingFormModel.aesKey">
                        </el-input>
                    </el-form-item>

                    <el-form-item>
                        <el-button @click="handleGenerateRsaPairs">生成RSA公钥私钥</el-button>
                        <el-button @click="handleGenerateAesKey">生成AES密钥</el-button>
                    </el-form-item>
                </el-form>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="settingDialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="settingDialogVisible = false">确 定</el-button>
             </span>
        </el-dialog>
    </div>
</template>

<script>


import pako from 'pako'
import JSEncrypt from 'jsencrypt'

import CryptoJS from 'crypto-js'
import sha256 from 'crypto-js/sha256';

let Base64 = require('js-base64').Base64
let md5 = require('js-md5');


// https://www.npmjs.com/package/crypto-js

export default {
    name: "StringEncDec",
    components: {},
    data() {
        return {
            formModel: {
                generateMode: 'batch',
                codeType: 'qrcode',
                content: '',
                height: 200,
                spaceWidth: 20
            },
            codeValues: [],

            settingDialogVisible: false,
            settingFormModel: {
                rsaPrivateKey: `-----BEGIN PRIVATE KEY-----

-----END PRIVATE KEY-----`,
                rsaPublicKey: `-----BEGIN PUBLIC KEY-----

-----END PUBLIC KEY-----`
            }
        }
    },
    computed: {
        cpCodeWrapperStyle() {
            let {spaceWidth} = this.formModel
            return {
                'display': 'inline-block',
                'margin-right': spaceWidth + 'px'
            }
        }
    },
    methods: {
        gzip(str) {
            let binaryString = pako.gzip(encodeURIComponent(str), {to: 'string'})
            return btoa(binaryString)
        },
        ungzip(b64Data) {
            let strData = atob(b64Data)
            // Convert binary string to character-number array
            let charData = strData.split('').map(function (x) {
                return x.charCodeAt(0)
            })
            // Turn number array into byte-array
            let binData = new Uint8Array(charData)
            // // unzip
            let data = pako.inflate(binData, {to: 'string'})
            // Convert gunzipped byteArray back to ascii string:
            /// strData = String.fromCharCode.apply(null, new Uint16Array(data))
            return decodeURIComponent(data)
        },
        handleGzip() {
            let {content} = this.formModel

            // this.$set(this.formModel, 'result', this.gzip(content))
            this.$set(this.formModel, 'result', this.gzip(content))
        },
        handleUnGzip() {
            let {content} = this.formModel

            try {
                this.$set(this.formModel, 'result', this.ungzip(content))
            } catch (e) {
                console.error(e)
                this.$set(this.formModel, 'result', 'GZIP解压失败')
            }
        },
        handleBase64Encode() {
            let {content} = this.formModel
            this.$set(this.formModel, 'result', Base64.encode(content))

        },
        handleBase64Decode() {
            let {content} = this.formModel
            this.$set(this.formModel, 'result', Base64.decode(content))
        },
        handleEncodeURI() {
            let {content} = this.formModel
            this.$set(this.formModel, 'result', encodeURI(content))
        },
        handleDecodeURI() {
            let {content} = this.formModel
            this.$set(this.formModel, 'result', decodeURI(content))
        },
        handleEncodeURIComponent() {
            let {content} = this.formModel
            this.$set(this.formModel, 'result', encodeURIComponent(content))
        },
        handleDecodeURIComponent() {
            let {content} = this.formModel
            this.$set(this.formModel, 'result', decodeURIComponent(content))
        },
        handleMd5() {
            let {content} = this.formModel
            this.$set(this.formModel, 'result', md5(content).toUpperCase())
        },

        handleAesEncrypt() {
            let {content} = this.formModel
            let {aesKey} = this.settingFormModel
            if (this.$strings.isBlank(aesKey)) {
                this.$message.warning('请先设置AES密钥')
                return
            }
            const encryptedData = CryptoJS.AES.encrypt(content, aesKey).toString()
            this.$set(this.formModel, 'result', encryptedData)
        },
        handleAesDecrypt() {
            let {content} = this.formModel
            let {aesKey} = this.settingFormModel
            if (this.$strings.isBlank(aesKey)) {
                this.$message.warning('请先设置AES密钥')
                return
            }
            const encryptedData = CryptoJS.AES.decrypt(content, aesKey).toString(CryptoJS.enc.Utf8)
            this.$set(this.formModel, 'result', encryptedData)
        },
        handleRsaEncrypt() {
            let {content} = this.formModel
            let {rsaPublicKey} = this.settingFormModel
            if (this.$strings.isBlank(rsaPublicKey)) {
                this.$message.warning('请先设置RSA公钥')
                return
            }
            const jsEncrypt = new JSEncrypt()
            jsEncrypt.setPublicKey(rsaPublicKey)
            const encryptedData = jsEncrypt.encrypt(content)
            if (!encryptedData) {
                this.$message.error('加密失败，请确认密钥正确性')
                return;
            }
            this.$set(this.formModel, 'result', encryptedData)
        },
        handleRsaDecrypt() {
            let {content} = this.formModel
            let {rsaPrivateKey} = this.settingFormModel
            if (this.$strings.isBlank(rsaPrivateKey)) {
                this.$message.warning('请先设置RSA私钥')
                return
            }

            const jsEncrypt = new JSEncrypt()
            jsEncrypt.setPrivateKey(rsaPrivateKey)
            const decryptedData = jsEncrypt.decrypt(content)
            if (!decryptedData) {
                this.$message.error('解密失败，请确认密钥正确性')
                return;
            }
            this.$set(this.formModel, 'result', decryptedData)
        },
        handleSha256Encrypt() {
            let {content} = this.formModel
            const hash = sha256(content).toString(CryptoJS.enc.Hex);
            this.$set(this.formModel, 'result', hash)
        },
        handleClear() {
            this.$set(this.formModel, 'content', '')
            this.$set(this.formModel, 'result', '')
        },

        handleOpenSettingDialog() {
            this.settingDialogVisible = true
        },
        handleGenerateRsaPairs() {
            // 定义密钥长度
            //获取密钥对
            function getRsaKeys(func) {
                window.crypto.subtle.generateKey(
                    {
                        name: "RSA-OAEP",
                        modulusLength: 2048, //can be 1024, 2048, or 4096
                        publicExponent: new Uint8Array([0x01, 0x00, 0x01]),
                        hash: {name: "SHA-512"}, //can be "SHA-1", "SHA-256", "SHA-384", or "SHA-512"
                    },
                    true, //whether the key is extractable (i.e. can be used in exportKey)
                    ["encrypt", "decrypt"] //must be ["encrypt", "decrypt"] or ["wrapKey", "unwrapKey"]
                ).then(function (key) {
                    window.crypto.subtle.exportKey(
                        "pkcs8",
                        key.privateKey
                    ).then(function (keydata1) {
                        window.crypto.subtle.exportKey(
                            "spki",
                            key.publicKey
                        ).then(function (keydata2) {
                            var privateKey = RSA2text(keydata1, 1);
                            var publicKey = RSA2text(keydata2);
                            func(privateKey, publicKey);
                        }).catch(function (err) {
                            console.error(err);
                        });
                    })
                        .catch(function (err) {
                            console.error(err);
                        });
                })
                    .catch(function (err) {
                        console.error(err);
                    });
            }

            function RSA2text(buffer, isPrivate = 0) {
                var binary = '';
                var bytes = new Uint8Array(buffer);
                var len = bytes.byteLength;
                for (var i = 0; i < len; i++) {
                    binary += String.fromCharCode(bytes[i]);
                }
                var base64 = window.btoa(binary);
                var text = "-----BEGIN " + (isPrivate ? "PRIVATE" : "PUBLIC") + " KEY-----\n";
                text += base64.replace(/[^\x00-\xff]/g, "$&\x01").replace(/.{64}\x01?/g, "$&\n");
                text += "\n-----END " + (isPrivate ? "PRIVATE" : "PUBLIC") + " KEY-----";
                return text;
            }

            let that = this
            getRsaKeys((privateKey, publicKey) => {
                that.$set(this.settingFormModel, 'rsaPrivateKey', privateKey)
                that.$set(this.settingFormModel, 'rsaPublicKey', publicKey)
            })

        },
        handleGenerateAesKey() {
            const key = CryptoJS.lib.WordArray.random(16).toString()
            this.$set(this.settingFormModel, 'aesKey', key)
        }
    },
    created() {
        this.handleGenerateRsaPairs()
        this.handleGenerateAesKey()
    }
}
</script>

<style scoped>

.code-container {
    width: 100%;
    /*height: 40vh;*/
    overflow-y: auto;
}

.code-wrapper {
    display: inline-block;
    margin-right: 10px
}

.el-radio {
    margin-right: 10px;
}
</style>
