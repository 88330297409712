import Vue from 'vue'

Vue.directive('drag', {
    bind(el, binding, vnode, oldVnode) {
        const dialogHeaderEl = el.querySelector('.el-dialog__header');
        const dragDom = el.querySelector('.el-dialog');

        dialogHeaderEl.style.cssText += ';cursor:move;'
        dragDom.style.cssText += ';top:0px;'

        // 获取原有属性 ie dom元素.currentStyle 火狐谷歌 window.getComputedStyle(dom元素, null);
        const sty = (() => {
            if (window.document.currentStyle) {
                return (dom, attr) => dom.currentStyle[attr];
            } else {
                return (dom, attr) => getComputedStyle(dom, false)[attr];
            }
        })()

        dialogHeaderEl.onmousedown = (e) => {
            // 鼠标按下，计算当前元素距离可视区的距离
            const disX = e.clientX - dialogHeaderEl.offsetLeft;
            const disY = e.clientY - dialogHeaderEl.offsetTop;

            const screenWidth = document.body.clientWidth; // body当前宽度
            const screenHeight = document.documentElement.clientHeight; // 可见区域高度(应为body高度，可某些环境下无法获取)

            const dragDomWidth = dragDom.offsetWidth; // 对话框宽度
            const dragDomheight = dragDom.offsetHeight; // 对话框高度

            const minDragDomLeft = dragDom.offsetLeft;
            const maxDragDomLeft = screenWidth - dragDom.offsetLeft - dragDomWidth;

            const minDragDomTop = dragDom.offsetTop;
            const maxDragDomTop = screenHeight - dragDom.offsetTop - dragDomheight;


            // 获取到的值带px 正则匹配替换
            let styL = sty(dragDom, 'left');
            let styT = sty(dragDom, 'top');

            // 注意在ie中 第一次获取到的值为组件自带50% 移动之后赋值为px
            if (styL.includes('%')) {
                styL = +document.body.clientWidth * (+styL.replace(/\%/g, '') / 100);
                styT = +document.body.clientHeight * (+styT.replace(/\%/g, '') / 100);
            } else {
                styL = +styL.replace(/\px/g, '');
                styT = +styT.replace(/\px/g, '');
            };

            document.onmousemove = function (e) {
                // 通过事件委托，计算移动的距离
                let left = e.clientX - disX;
                let top = e.clientY - disY;

                // 边界处理
                if (-(left) > minDragDomLeft) {
                    left = -(minDragDomLeft);
                } else if (left > maxDragDomLeft) {
                    left = maxDragDomLeft;
                }

                if (-(top) > minDragDomTop) {
                    top = -(minDragDomTop);
                } else if (top > maxDragDomTop) {
                    top = maxDragDomTop;
                }

                // 移动当前元素
                dragDom.style.cssText += `;left:${left + styL}px;top:${top + styT}px;`;
            };

            document.onmouseup = function (e) {
                document.onmousemove = null;
                document.onmouseup = null;
            };
        }
    }
})


// 对话框拖动调节宽度
Vue.directive('dialog-drag-width', {
    bind(el, binding, vnode, oldVnode) {
        Vue.nextTick(() => {

            // 最外层的 dialog wrapper
            let wrapper = binding.value.$el;
            const dragDom = wrapper.querySelector('.el-dialog');


            // 设置split bar的样式
            el.style.position = "absolute";
            el.style.top = "0px";
            el.style.left = "0px";
            el.style.width = "5px";
            el.style.height = (dragDom.offsetHeight - 2) + "px";
            el.style.cursor = "col-resize";
            el.style.opacity = "5";
            el.style.backgroundColor = "transparent";

            // 给split bar添加图标
            let span = document.createElement("span");
            let li = document.createElement("li");
            li.style.position = "absolute";
            li.style.top = "50%";
            li.style.left = "50%";
            li.style.transform = "rotate(90deg)"
            li.setAttribute("class", "el-icon-d-caret")

            span.appendChild(li)
            el.appendChild(span)

            // 是否有自定义宽度
            let rawWidth = 0;
            if (wrapper.style.width != "") {
                rawWidth = wrapper.style.width
            } else {
                rawWidth = '40%'
            }

            wrapper.style.width = "100%"
            let maxWidth = wrapper.offsetWidth
            wrapper.style.width = rawWidth


            // 计算出左边的距离
            let left = maxWidth - wrapper.offsetWidth;
            wrapper.style.left = `${left}px`

            window.addEventListener("resize", (e) => {
                el.style.height = (dragDom.offsetHeight - 2) + "px";

                // 记录原来的宽度 及 屏占比
                let oldWidth = wrapper.offsetWidth
                let oldWidthPercent = oldWidth / maxWidth * 1.0

                wrapper.style.width = "100%"
                maxWidth = wrapper.offsetWidth

                oldWidthPercent = oldWidthPercent > 100 ? 100 : oldWidthPercent

                // 设置left及width
                wrapper.style.left = (maxWidth - (maxWidth * oldWidthPercent)) + "px";
                wrapper.style.width = `${maxWidth * oldWidthPercent}px`

            })


            el.onmousedown = (e) => {

                // 当前点击时Left的值
                let offsetLeft = e.clientX;

                document.onmousemove = function (e) {
                    e.preventDefault(); // 移动时禁用默认事件

                    if (offsetLeft == e.clientX) {
                        return;
                    }

                    // 移动的距离
                    let moveDistance = offsetLeft - e.clientX;

                    wrapper.style.left = (wrapper.offsetLeft - moveDistance) + "px";
                    wrapper.style.width = (maxWidth - wrapper.offsetLeft) + "px";

                    // 已经拉到最左边了，最大化显示
                    if (wrapper.offsetLeft <= 0) {
                        wrapper.style.left = "0px";
                        wrapper.style.width = maxWidth + "px";
                        return;
                    }

                    // 已经拉到最右边了
                    if (wrapper.offsetLeft >= (maxWidth - 50)) {
                        wrapper.style.left = (maxWidth - 50) + "px";
                        wrapper.style.width = "50px";
                        return;
                    }

                    // 更新左边当前位置
                    offsetLeft = e.clientX;

                };

                document.onmouseup = function (e) {
                    document.onmousemove = null;
                    document.onmouseup = null;
                };
            }
        })
    }
})

