<template>
    <div>
        <el-card class="box-card">
            <el-form label-width="70px" @submit.native.prevent size="small">
                <el-form-item label="链接地址">
                    <el-input
                        type="textarea"
                        :rows="10"
                        placeholder="链接地址"
                        v-model="formModel.content" resize="none" show-word-limit>
                    </el-input>
                </el-form-item>
                <el-form-item label="密码">
                    <el-input
                        placeholder="请输入密码"
                        v-model="formModel.password">
                    </el-input>
                </el-form-item>
                <el-row>
                    <el-form-item>
                        <el-button-group>
                            <el-button icon="el-icon-s-promotion" @click="handleParse('cloudreve')">Cloudreve</el-button>
                            <el-button icon="el-icon-link" @click="handleOpenUrl(`https://cloud.yuntao.xyz`)" >Go</el-button>
                        </el-button-group>
                        <el-divider direction="vertical"></el-divider>

                        <el-button-group>
                            <el-button icon="el-icon-s-promotion" @click="handleParse('easychuan')">Easychuan</el-button>
                            <el-button icon="el-icon-link"  @click="handleOpenUrl(`https://easychuan.cn/`)" >Go</el-button>
                        </el-button-group>
                        <el-divider direction="vertical"></el-divider>

                        <el-button-group>
                            <el-button icon="el-icon-s-promotion" @click="handleParse('lanzou')">蓝奏云</el-button>
                            <el-button icon="el-icon-link"  @click="handleOpenUrl(`https://www.lanzou.com/`)" >Go</el-button>
                        </el-button-group>
                        <el-divider direction="vertical"></el-divider>

                        <el-button-group>
                            <el-button icon="el-icon-s-promotion" @click="handleParse('nainiu')">奶牛快传</el-button>
                            <el-button icon="el-icon-link"  @click="handleOpenUrl(`https://cowtransfer.com/`)" >Go</el-button>
                        </el-button-group>
                        <el-divider direction="vertical"></el-divider>
                        <el-button @click="handleClear">清空</el-button>
                    </el-form-item>
                </el-row>

            </el-form>
        </el-card>
        <div style="height: 10px"></div>
        <el-card class="box-card code-container" :body-style="{ paddingBottom: '0px' }">
            <el-form label-width="70px" @submit.native.prevent size="small">

                <el-form-item label="结果">
<!--                    element-loading-text="拼命解析中"
                    element-loading-spinner="el-icon-loading"
                    element-loading-background="rgba(0, 0, 0, 0.8)"-->
                    <el-input
                        v-loading="loading"
                        type="textarea"
                        :rows="12"
                        placeholder="结果"
                        v-model="formModel.result" resize="none" show-word-limit>
                    </el-input>
                </el-form-item>
                <el-form-item label="" >
                    <el-link type="primary" @click="handleCopyWget($event)" :disabled="$strings.isBlank(formModel.result)">wget下载</el-link>
                    <el-divider direction="vertical"></el-divider>
                    <el-link type="primary" @click="handleCopyCurl($event)" :disabled="$strings.isBlank(formModel.result)">curl下载</el-link>
                </el-form-item>
            </el-form>


        </el-card>
    </div>
</template>

<script>


export default {
    name: "NetdiskParse",
    components: {},
    data() {
        return {
            formModel: {
                generateMode: 'batch',
                codeType: 'qrcode',
                content: '',
                height: 200,
                spaceWidth: 20
            },
            codeValues: [],
            loading: false,

            refererMap: {
                'nainiu': `https://cowtransfer.com/`
            },
            referer: null // 部分网盘有防盗链
        }
    },
    computed: {
        cpCodeWrapperStyle() {
            let {spaceWidth} = this.formModel
            return {
                'display': 'inline-block',
                'margin-right': spaceWidth + 'px'
            }
        }
    },
    methods: {
        handleCopyWget(e) {
            /*-U '${ua}'*/
            // url 需要加单引号，原因是 URL 里面包含特殊字符 比如 &，导致 URL 被截断，造成请求 URL 不完整
            let ua = 'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/72.0.3626.121 Safari/537.36'
            let command = `wget ${this.referer ? `--referer='${this.referer}'` : ''} --content-disposition   '${this.formModel.result}'`

            this.$clipboard(command, e)
        },
        handleCopyCurl(e) {
            /* -A '${ua}'*/
            let ua = 'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/72.0.3626.121 Safari/537.36'
            let command = `curl ${this.referer ? `-e '${this.referer}'` : ''} -J -O   '${this.formModel.result}'`

            this.$clipboard(command, e)
        },
        handleParse(wpName) {
            let {content, password} = this.formModel

            if (this.$strings.isBlank(content)) {
                return
            }

            this.loading = true

            let referer = this.refererMap[wpName]
            this.referer = referer

            let wpMap = {
                'cloudreve': this.cloudreveParse.bind(this),
                'easychuan': this.easychuanParse.bind(this),
                'lanzou': this.lanzoumParse.bind(this),
                'nainiu': this.nainiuParse.bind(this),
            }
            let fn = wpMap[wpName]
            this.$set(this.formModel, 'result', '')
            fn().then(result => {
                this.$set(this.formModel, 'result', result)
            }).catch(({message}) => {
                this.$message.error(message)
            }).finally(() => {
                this.loading = false
            })
        },
        cloudreveParse() {
            let {content, password} = this.formModel

            return new Promise((resolve, reject) => {
                // 支持分项连接替换  http://cloud.yuntao.xyz/s/l5i5
                let reg = /(http[s]*):\/\/(.*?)(\/s\/)(.*?)/g
                if (!reg.test(content)) {
                    return reject({message: '请确认链接是否正确'})
                }

                content = content.replace(reg, '$1://$2/api/v3/share/download/$4')

                if (this.$strings.isNotBlank(password)) {
                    content += '?password=' + password
                }

                // http://cloud.yuntao.xyz/api/v3/share/download/l5i5?password=20c8kg
                // {"code":0,"data":"http://cloud.yuntao.xyz/api/v3/file/download/vFuDtSWfiYl8wmtC?sign=m0UEnEEyylx7xrCoIYi9JHX7lX-YGotDHrLhivuDzmY%3D%3A1638768137","msg":""}
                this.$http.put(content).then(resp => {
                    let {code, data, msg} = resp
                    if (code != 0) {
                        return reject({message: msg})
                    }
                    resolve(data)
                })
            })


        },
        nainiuParse() {
            let {content, password} = this.formModel


            return new Promise((resolve,reject) => {
                // 支持分项连接替换  https://cowtransfer.com/s/578c9c040eea4d
                let reg = /(http[s]*):\/\/(.*?)(\/s\/)(.*)/ig
                /*if (!reg.test(content)) {
                    this.$message.warning('请确认链接是否正确 1')
                    return
                }*/
                let arr = reg.exec(content)
                if (arr == null || arr.length != 5) {
                    return reject({message: '请确认链接是否正确'})
                }
                let pickCode = arr[4]

                let url = `https://tools.yuntao.xyz/api/nainiu/core/api/transfer/share?uniqueUrl=${pickCode}`
                this.$http.get(url).then(resp => {
                    let {message,data} = resp

                    if (message != 'success') {
                        return reject({message: '请确认是否已上传文件'})
                    }
                    // 有多个文件时，取第一个
                    let {guid, transferName, firstFile: {id}} = data


                    url = `https://tools.yuntao.xyz/api/nainiu/core/api/transfer/share/download/links?transferGuid=${guid}&title=${encodeURI(transferName)}&fileId=${id}`
                    // let data = {code: pickCode, filekey, use_https: true}
                    this.$http.get(url, data).then(resp => {
                        if (resp.message != 'success') {
                            return reject({message: resp.message})
                        }
                        resolve( resp.data[0])
                    })
                })
            })
        },
        easychuanParse() {
            let {content, password} = this.formModel


            return new Promise((resolve,reject) => {
                // 支持分项连接替换  https://easychuan.cn/r/2yjzb?t=ff
                let reg = /(http[s]*):\/\/(.*?)(\/r\/)(.*?)\?/ig
                /*if (!reg.test(content)) {
                    this.$message.warning('请确认链接是否正确 1')
                    return
                }*/
                let arr = reg.exec(content)
                if (arr == null || arr.length != 5) {
                    return reject({message: '请确认链接是否正确'})
                }
                let pickCode = arr[4]

                let url = `https://tools.yuntao.xyz/api/easychuan/files/${pickCode}`
                this.$http.get(url).then(resp => {
                    let {files = []} = resp

                    if (files.length == 0) {
                        return reject({message: '请确认是否已上传文件'})
                    }
                    // 有多个文件时，取第一个
                    let {filekey, filename, filesize} = files[0]

                    url = 'https://tools.yuntao.xyz/api/easychuan/files/link'
                    let data = {code: pickCode, filekey, use_https: true}
                    this.$http.post(url, data).then(resp => {
                        resolve( resp.url)
                    })
                })
            })
        },
        lanzoumParse() {

            let {content, password} = this.formModel

            // 支持分项连接替换  https://wwap.lanzoum.com/iWb6J0jf5gef
            let getSign = () => {
                return new Promise((resolve, reject) => {
                    let reg = /(http[s]*):\/\/(.*?)\/(.*)/ig

                    let arr = reg.exec(content)
                    if (arr == null || arr.length != 4) {
                        reject('请确认链接是否正确')
                        return
                    }
                    let pickCode = arr[3]
                    let url = `https://tools.yuntao.xyz/api/lanzoum/wwap/${pickCode}`

                    this.$http.get(url).then(resp => {
                        let data = (resp.data)
                        // reg = /action=downprocess&sign=(.*_c_c)&p=/ig
                        reg = /var skdklds = '(.*?)'/ig
                        arr = reg.exec(data)
                        if (arr == null || arr.length != 2) {
                            reject('解析失败')
                            return
                        }

                        let sign = arr[1]
                        resolve(sign)
                    }).catch(err => reject(err.message))
                })
            }

            let getPreDownUrl = (sign, password) => {
                return new Promise((resolve, reject) => {
                    let url = `https://tools.yuntao.xyz/api/lanzoum/wwap/ajaxm.php`
                    let params = `action=downprocess&sign=${sign}&p=${password || ''}`
                    this.$http.post(url, params, {
                        headers: {'Content-Type': 'application/x-www-form-urlencoded'}
                    }).then(resp => {
                        let data = resp.data
                        if (data.zt != 1) {
                            return reject(data.inf)
                        }
                        // https://developer.lanzoug.com/file/?UDZVaw4/ATBWXwY+V2JTPwA/Bj4FawomUjZRMQY9AyRTNQY4CzoFNAEIBzgENF1hBmUFNANkU2BQZFRhBmMGMlB2VWQOIAFoVjMGYFcyU2YAfAZzBSoKNVJnUWcGaAMwU3wGYAtkBX4BZQdnBH9dPwY/BTkDbVNhUDZUMAZrBj5QNlVkDmUBZ1ZhBmVXYlNqADkGMgU4CjFSN1EzBm4DMFM1BmQLYQVjAWQHNAQxXXIGPwVmAztTd1AiVCEGPwZ1UDlVZg5uAWZWMAZoVzBTYgBuBjEFfAp8UjxROgY/A2RTbgZgC2AFZgFlB2EEYl1oBmwFOQNqU39QeVR0BjwGa1AnVT8OYgFjVjcGYlczU2AAawYzBWoKPVJzUSIGKgN1U24GYAtgBWYBZQdhBGJdbAZkBTIDblN3UCJUOwYqBjpQZFUwDn0BYVY4Bn5XMVNhAG4GLQViCjo=
                        resolve('https://tools.yuntao.xyz/api/lanzoum/developer/file/' + data.url)
                    }).catch(err => reject(err.message))
                })
            }

            let getValidResult = (pdUrl) => {
                return new Promise((resolve, reject) => {

                    /*fetch(pdUrl, {
                        mode: "cors",
                    })
                        .then((response) => {
                            console.log('response..........',response)

                            let data = response.text()

                            let reg = /'file':'(.*?)','el':el,'sign':'(.*?)'/ig
                            let arr = reg.exec(data)
                            if (arr == null || arr.length != 3) {
                                reject('解析失败')
                                return
                            }
                            resolve({file: arr[1], sign: arr[2]})
                        })
                        .then(res => console.log(res))
                        .catch(err => {
                            console.log('aaaaaaaaaaaaaaaaaaa',err)
                    })*/

                    this.$http.get(pdUrl, {}).then(resp => {
                        let xLocation = resp.headers['x-location']
                        if (!this.$strings.isBlank(xLocation)) {
                            // 不需要验证，直接返回下载地址
                            return resolve({xLocation})
                        }
                        let data = resp.data
                        let reg = /'file':'(.*?)','el':el,'sign':'(.*?)'/ig
                        let arr = reg.exec(data)
                        if (arr == null || arr.length != 3) {
                            reject('解析失败')
                            return
                        }
                        resolve({file: arr[1], sign: arr[2]})
                    }).catch(err => {
                        reject(err.message)
                    })
                })
            }
            let getDownloadUrl = ({file, sign}) => {
                return new Promise((resolve, reject) => {
                    let url = 'https://tools.yuntao.xyz/api/lanzoum/developer/file/ajax.php'

                    let params = `file=${encodeURIComponent(file)}&el=2&sign=${encodeURIComponent(sign)}`
                    console.log(params)
                    this.$http.post(url, params, {
                        headers: {'Content-Type': 'application/x-www-form-urlencoded'}
                    }).then(resp => {
                        let data = resp.data
                        if (data.zt != 1) {
                            return reject(data.inf)
                        }
                        resolve(data.url)
                    }).catch(err => reject(err.message))
                })
            }
            let sleep = (ms) => {
                return new Promise((resolve, reject) => {
                    setTimeout(() => resolve(), ms)
                })
            }

            return new Promise(async (resolve, reject) => {
                try {
                    let sign = await getSign()
                    let pdUrl = await getPreDownUrl(sign, password)
                    let vr = await getValidResult(pdUrl)

                    let {xLocation} = vr
                    let downloadUrl = xLocation
                    if (this.$strings.isBlank(xLocation)) {
                        // 如果为空，代表网络异常，需要进行验证，延时两秒请求，否则会sign异常
                        await sleep(2000)
                        downloadUrl = await getDownloadUrl(vr)
                    }

                    // this.$set(this.formModel, 'result', downloadUrl)

                    resolve(downloadUrl)
                } catch (e) {
                    // this.$message.error(e)
                    reject({message: e})
                }
            })
        },
        handleOpenUrl(url) {
          window.open(url, '_blank')
        },
        handleClear() {
            this.$set(this.formModel, 'content', '')
            this.$set(this.formModel, 'result', '')
        }
    }
}
</script>

<style scoped>

.code-container {
    width: 100%;
    /*height: 40vh;*/
    overflow-y: auto;
}

.code-wrapper {
    display: inline-block;
    margin-right: 10px
}

.el-radio {
    margin-right: 10px;
}
</style>
